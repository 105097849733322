import { awareTerms, blockLink, oknokAMT, oknokGP, saveLink, submitFiles, submitGrades, updateEvaluator, updateGrades } from '../infra/dataIngester'

export async function createLink (doctorId, link) {
  var response
  try {
      response = await saveLink({...{doctorId, link}})
      return response.link_id? true : false
  } 
  catch (error) {
    return false
  }
}

export async function changeStatusLink ({doctorId, link, value}) {
  var response
  try {
      response = await blockLink({...{doctorId, link, value}})
      return true
  } 
  catch (error) {
    return false
  }
}

export async function updateTermsStatus (user) {
  var response
  try {
      response = await awareTerms({...{user}})
      return true
  } 
  catch (error) {
    return false
  }
}

export async function submitCaseFiles ({formData}) {
  var response
  try {
      response = await submitFiles({...{formData}})
      return response.mensagem
  } 
  catch (error) {
    return null
  }
}

export async function submitCaseGrades ({body}) {
  var response
  try {
      response = await submitGrades({...{body}})
      return true
  } 
  catch (error) {
    return false
  }
}
export async function updateCaseGrades ({body}) {
  var response
  try {
      response = await updateGrades({...{body}})
      return true
  } 
  catch (error) {
    return false
  }
}

export async function blockEvaluator ({body}) {
  var response
  try {
      response = await updateEvaluator({...{body}})
      return true
  } 
  catch (error) {
    return false
  }
}

export async function approve (id) {
    var response
    try {
        response = await oknokGP({...{id, valor: 1}})
        return true
    } 
    catch (error) {
      return false
    }
}
export async function reject (id) {
  var response
  try {
      response = await oknokGP({...{id, valor: 0}})
      return true
  } 
  catch (error) {
    return false
  }
}

export async function approveAMT (id) {
  var response
  try {
      response = await oknokAMT({...{id, valor: 1}})
      return true
  } 
  catch (error) {
    return false
  }
}
export async function rejectAMT (id) {
var response
try {
    response = await oknokAMT({...{id, valor: 0}})
    return true
} 
catch (error) {
  return false
}
}