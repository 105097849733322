import React, {useCallback, useEffect, useState} from "react"
import { Alert, Box, Button, CircularProgress, Grid, Paper, Slider, Snackbar, Typography } from "@mui/material"
import {PdfViewer} from "../components"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAuth } from "../context/useAuth"
import debounce from "debounce"
import { submitCaseGrades, updateCaseGrades } from "../data/ingester"
import { CheckCircle } from "@mui/icons-material"

export default function Evaluation() {
    const [loading, setLoading] = useState(false)
    const [snackOpen, setSnackOpen] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [evaluation, setEvaluation] = useState(null)
    const [note, setNote] = useState(0)
    const location = useLocation()
    const navigate = useNavigate()
    const { loggedUser } = useAuth()

    useEffect(() => {
        if(location.state){
            const lastGrades= location.state.lastGrades
            var currentNote = Object.values(lastGrades)?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0,
            )
            setNote(currentNote)
            setEvaluation(
            {
                idAttachment: location.state.idAttachment,
                attachment: location.state.attachment,
                categoryName: location.state.categoryName,
                criteria:{
                    caso: {
                        id: 1,
                        desc: "Caso Original – impacto clínico / inovação e originalidade",
                        value: lastGrades[1]?.value || 0
                    },
                    relato:{
                        id: 2,
                        desc: "Relato Detalhado – dados clínicos (ECOG, idade, etc.), antecedentes patológicos, histórico familiar, definição do diagnóstico, data de início da doença e progressões, condutas.",
                        value: lastGrades[2]?.value || 0
                    },
                    imagem:{
                        id: 3,
                        desc: "Relevância da Imagem – imagem relevante ao caso + definição e qualidade",
                        value: lastGrades[3]?.value || 0
                    },
                    discussao:{
                        id: 4,
                        desc: "Discussão adequada da literatura relacionado ao desfecho do caso",
                        value: lastGrades[4]?.value || 0
                    },
                    referencias:{
                        id: 5,
                        desc: "Lista de referência bibliográfica (norma vancouver)",
                        value: lastGrades[5]?.value || 0
                    }
                }
            }
            )
        }
    }, [])

    const updateEvaluation = ({key, value}) => {
        let newEvaluation = {...evaluation}
        setNote(note - newEvaluation.criteria[key].value + value)
        newEvaluation.criteria[key].value = value
        setEvaluation(newEvaluation)
    }

    const saveEvaluation = async () => {
        const criteria = Object.values(evaluation.criteria)
        const grades = criteria.map((obj) => {
            return {
                "idPergunta": obj.id,
                "nota": obj.value }
          }
                                 );
        var body = {
            "login": loggedUser?.username,
            "idAnexo": evaluation.idAttachment,
            "notas": grades
        }
        setLoading(true)
        const resp = Object.keys(location.state.lastGrades).length == 0
        ? await submitCaseGrades({...{body}})
        : await updateCaseGrades({...{body}})

        if(resp) {
            setSuccess(true)
            redirect()
        }
        else {
            setSnackOpen(true)
            setDisableButton(false)
        }
        setLoading(false)
    }

    const goBack = () => {
        navigate("/list", 
        { state:
            { indexTab: location.state.indexTab || 0 }
        })
    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }
    const redirect = () => {
        sleep(3000).then(() => { goBack() })
    }  
    
    const handleDebounceLink = debounce(saveEvaluation, 300)
    const handleCallBackSubmission = useCallback(()=>{
        setDisableButton(true)
        handleDebounceLink()
    }, [evaluation, loggedUser])

    const handleSnackClose = () => {
        setSnackOpen(false)
    }

    return (
        <Box className="mainNoWrapBox" sx={{gap:5}}>
            { evaluation && 
            <>
            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                autoHideDuration={6000}
                open={snackOpen}
                onClose={handleSnackClose}
            >
                <Alert
                    onClose={handleSnackClose}
                    severity={"error"}
                    variant="standard"
                    sx={{ width: '100%', p:2, textTransform:"uppercase"}}
                >
                    {"Erro: Não foi possível realizar a operação."}
                </Alert>
            </Snackbar>

            <PdfViewer url={`https://aveluchampions.com/pdfs/${location?.state?.attachment}`} readonly={true}/>
            
            <Box className="evaluationBox">
                <Box className="evaluation">
                {Object.keys(evaluation).length > 0 &&
                    <> 
                    <Typography variant="h6" marginBottom={2} >
                        {`Categoria: ${evaluation.categoryName}`}           
                    </Typography>
                    <Typography className="tableCellText" variant="h6" marginBottom={2} title={evaluation.attachment}>
                        {`Tema: ${evaluation.attachment}`}           
                    </Typography>
                    <Paper className="legendPaper" elevation={0}>
                        <Typography className="titleAMT" variant="h6">
                            {"Legenda pontuação"}
                        </Typography>
                        <Box sx={{textAlign:"left", m:2}}>
                            <Typography className="titleAMT" variant="body1">
                                {" 0 - não entregou/ inexistente"}
                            </Typography>
                            <Typography className="titleAMT" variant="body1">
                                {" 1 - não atende/ baixa informação ou qualidade"}
                            </Typography>
                            <Typography className="titleAMT" variant="body1">
                                {" 2 - atende/ preenche todos os requisitos"}
                            </Typography>
                            <Typography className="titleAMT" variant="body1">
                                {" 3 - supera/ entrega a mais que o esperado"}
                            </Typography>
                        </Box>
                    </Paper>
                    {
                    Object.keys(evaluation.criteria).map((key, index)=>(
                        <Box key={index} sx={{display:"block", mb:3}} >
                            <Typography variant="subtitle1" >
                                {evaluation.criteria[key].desc}           
                            </Typography>
                            <Box sx={{display:"flex"}} gap={3} >
                                <Slider
                                aria-label={key}
                                defaultValue={0}
                                value={evaluation.criteria[key].value}
                                valueLabelDisplay="off"
                                getAriaValueText={(value)=>value}
                                shiftStep={1}
                                step={1}
                                marks
                                min={0}
                                max={3}
                                onChange={(_, value)=> updateEvaluation({key, value})}
                                sx={{m:1}}
                                />
                                <Typography variant="h6" >
                                    {evaluation.criteria[key].value}           
                                </Typography>
                            </Box>
                            
                        </Box>
                    ))}
                    </>
                }
                </Box>
                <Box className="evaluationButtonArea">
                    <Typography variant="h6" className="total" >
                        {note}           
                    </Typography>

                    {errorMessage.length > 0 &&
                        <Paper className="errorPaper" elevation={0}>{errorMessage}</Paper> 
                    }
                    {!loading && !success &&
                    
                    <Button className="avaliateButton bottomButton" disabled={note <= 0 || disableButton} onClick={handleCallBackSubmission} >
                        <Typography variant="body2" >
                        {"Salvar notas"}           
                        </Typography>
                    </Button>
                    }
                    {loading &&
                    <Box className="progress" height={"40px"} visibility="visible">
                        <CircularProgress />
                    </Box>
                    }
                    {success &&
                    <>
                        <Typography variant="body2" >
                            {"Você será redirecionado ... "}           
                        </Typography>
                        <Paper className="successPaper bottomButton" elevation={0}>
                            <Typography variant="body2" >
                                {"AVALIAÇÃO ENVIADA!"}
                            </Typography>
                            <CheckCircle fontSize="large"/>
                        </Paper> 
                    </>
                    }
                </Box> 

            </Box>
            </>
            } 
        </Box>
    )
}
