import React, { useState } from "react"
import { Alert, Box, Button, CircularProgress, Grid, Link, Paper, Snackbar, Tab, Tabs, Typography } from "@mui/material"
import OutlinedInput from '@mui/material/OutlinedInput'
import { sendMailCredentials } from "../data/retriever"

const RecoverPwd = () => {
    const [loading, setLoading] = useState(false)
    const [snackOpen, setSnackOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [username, setUsername] = useState("")

    const handleName = (event) => {
        setUsername(event.target.value)
    }

    const sendPassword = async () => {
        setLoading(true)
        if(username) {
            const ret = await sendMailCredentials(username)
            if(ret){
                setErrorMessage("") 
                setSnackOpen(true)
                setUsername("")
            } else {
                setErrorMessage("E-mail não encontrado!")  
            }
        }
        setLoading(false)
    }

    const handleSnackClose = () => {
        setSnackOpen(false)
    }
    return (
        <Box className="loginBox">
            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                autoHideDuration={6000}
                open={snackOpen}
                onClose={handleSnackClose}
            >
                <Alert
                    onClose={handleSnackClose}
                    severity={"success"}
                    variant="standard"
                    sx={{ width: '100%', p:2, textTransform:"uppercase"}}
                >
                    {"Dados enviados com sucesso!"}
                </Alert>
            </Snackbar>

            <Box className="loginLogo"/>
            <Grid container className="login">
            <Box className="loginItem">
                <Typography className="title">
                    {"Recuperar senha"}
                </Typography>
                </Box>
                <Box className="loginItem">
                <Typography sx={{fontWeight:"bold", alignSelf:"center"}} >
                    {"Email"}
                </Typography>
                <OutlinedInput 
                    className="avaliateButton" 
                    value={username}
                    onKeyUp={(event) => {if(event.key == "Enter") sendPassword()}} 
                    onChange={handleName} 
                />
                </Box> 
                <Box className="loginItem"> 
                {!loading &&
                    <>
                        {errorMessage?.length > 0 &&
                            <Paper className="errorPaper" elevation={0}>{errorMessage}</Paper> 
                        }
                        <Button disabled={!username} onClick={sendPassword} sx={{width:"100%"}}>
                            <Typography variant="body2" >
                            {"Solicitar a senha"}           
                            </Typography>
                        </Button>
                    </>
                }
                {loading &&
                    <Box className="progress" visibility="visible">
                        <CircularProgress />
                    </Box>
                }
                </Box>
            </Grid> 
        </Box> 
        )
}
export default RecoverPwd
