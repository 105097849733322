import { 
  authenticate, 
  listSubmittedClinicCasesResponse, 
  getDoctorsWithTokenResponse, 
  listCaseAttachmentsResponse, 
  listValidatedClinicCasesResponse, 
  listCaseAttachmentsAMTResponse, 
  listValidatedClinicCasesAMTResponse, 
  listSubmittedClinicCasesAMTResponse, 
  listRejectedClinicCasesResponse,
  listRejectedClinicCasesAMTResponse,
  getDoctors, 
  checkLink, 
  listEvaluated, 
  listToEvaluate, 
  changePassword,
  getEvaluators,
  getCredentials,
  getDash,
  getEvalDash,
  lastAvailableLink,
  sendCredentials
} from '../infra/dataRetriever'


// LOGIN
export async function checkCredentials({ username, password }) {
  var response
  try {
    response = await authenticate({ ...{ username, password } })
    const profile = response.perfil
    const reset = response.reset
    const termo = response.termo
    if (profile?.length > 0) {
      return {
        "username": username,
        "profile": profile,
        "reset": reset,
        "termo": termo
      }
    }
    else {
      return null
    }
  }
  catch (_) {
    return null
  }
}

export async function sendMailCredentials(username) {
  var response
  try {
    response = await getCredentials(username)
    const to = response.email
    const password = response.password
    const message = `
    Solicitação de senha Aveluchampions
    
    Senha: ${password}
    \n\n
    Para garantir a segurança da sua conta, recomendamos que você altere sua senha imediatamente após o login. 
    Você pode fazer isso acessando a seção "Minha Conta" no portal Aveluchampions.
    `
    const subject = "Aveluchampions - Esqueci minha senha"
    if (response.email) {
      await sendCredentials({...{to, message, subject}})
    }
    return true
  }
  catch (_) {
    return false
  }
}

export async function updateCredentials({ username, password }) {
  var response
  try {
    response = await changePassword({ ...{ username, password } })
    return true
  }
  catch (_) {
    return null
  }
}

// LINK MÉDICO
export async function checkValidLink(link) {
  var response
  try {
    response = await checkLink(link)
    return response
  }
  catch (_) {
    return false
  }
}

export async function checkAvailableLink(doctorId) {
  var response
  try {
    response = await lastAvailableLink(doctorId)
    return response.link
  }
  catch (_) {
    return false
  }
}

const getResponse = async (func, args) => {
  var response = []
  try {
    response = await func(args)
  }
  catch (_) {
  }
  return response
}

export async function getAllEvaluators() {
  return getResponse(getEvaluators)
}

// CASOS ENVIADOS
export async function getSubmittedClinicCases(doctorId) {
  return getResponse(listSubmittedClinicCasesResponse, doctorId)
}

export async function getSubmittedClinicCasesAMT(doctorId) {
  return getResponse(listSubmittedClinicCasesAMTResponse, doctorId)
}

// CASOS VALIDADOS
export async function getValidatedClinicCases(doctorId) {
  return getResponse(listValidatedClinicCasesResponse, doctorId)
}

export async function getValidatedClinicCasesAMT(doctorId) {
  return getResponse(listValidatedClinicCasesAMTResponse, doctorId)
}

// CASOS REJEITADOS
export async function getRejectedClinicCases(doctorId) {
  return getResponse(listRejectedClinicCasesResponse, doctorId)
}

export async function getRejectedClinicCasesAMT(doctorId) {
  return getResponse(listRejectedClinicCasesAMTResponse, doctorId)
}

// DETALHES DO CASOS CLÍNICOS
export async function getCaseAttachments(token) {
  return getResponse(listCaseAttachmentsResponse, token)
}

export async function getCaseAttachmentsAMT(token) {
  return getResponse(listCaseAttachmentsAMTResponse, token)
}

// MÉDICOS COM LINKS
export async function getDoctorsWithToken() {
  return getResponse(getDoctorsWithTokenResponse)
}

// MÉDICOS
export async function getAllDoctors() {
  return getResponse(getDoctors)
}

// CASOS CLÍNICOS PENDENTES DE AVALIACAO
export async function getClinicCases(login) {
  return getResponse(listToEvaluate, login)
}

// CASOS CLÍNICOS AVALIADOS
export async function getEvaluatedCases(login) {
  return getResponse(listEvaluated, login)
}

export async function getCasesDashboard() {
  return getResponse(getDash)
}

export async function getEvalCasesDashboard() {
  return getResponse(getEvalDash)
}