import axios from "axios"

const headers = {
    'Content-Type': 'application/json'
}
const api = process.env.REACT_APP_API_URL_BASE

const getData = ({service, headers}) => {
    return new Promise((resolve, reject) => {
        axios
        .get(
          api + service,
          {headers: headers}
        )
        .then(({data}) => {
            resolve(data)
        })
        .catch((e) => {
            reject(e)
        })
    })
}
const postData = ({service, body, headers}) => {
    return new Promise((resolve, reject) => {
        axios
        .post(
          api + service,
          body,
          {headers: headers}
        )
        .then(({data}) => {
            resolve(data)
        })
        .catch((e) => {
            reject(e)
        })
    })
}

export function getDash () {
    const service = "casosdashboard"
    return getData({...{service, headers}})
}
export function getEvalDash () {
    const service = "avaliadosdashboard"
    return getData({...{service, headers}})
}

export function getDoctors () {
    const service = "medicos"
    return getData({...{service, headers}})
}

export function getDoctorsWithTokenResponse () {
    const service = "medicosComTokens"
    return getData({...{service, headers}})
}

export function getEvaluators () {
    const service = "avaliadores"
    return getData({...{service, headers}})
}

export function authenticate ({username, password}) {
    const service = "login"
    const body = {
        "username": username,
        "password": password
    }
    return postData({...{service, body, headers}})
}

export function changePassword ({username, password}) {
    const service = "updatepws"
    const body = {
        "user": username,
        "pws": password
    }
    return postData({...{service, body, headers}})
}

export function getCredentials (username) {
    const service = "lembrar_senha"
    const body = {
        "username": username
    }
    return postData({...{service, body, headers}})
}

export function checkLink (link) {
    const service = "checklink"
    const body = {
        "link": link
    }
    return postData({...{service, body, headers}})
}

export function lastAvailableLink (doctorId) {
    const service = "seek"
    const body = {
        "idmedico": doctorId
    }
    return postData({...{service, body, headers}})
}

export function sendCredentials ({to, message, subject}) {
    const service = "sendemail"
    const body = {
        "email": to,
        "message": message,
        "subject": subject,
    }
    return postData({...{service, body, headers}})
}

export function listSubmittedClinicCasesResponse (doctorId) {
    const service = "enviados"
    const body = {
        "idmedico": doctorId
    }
    return postData({...{service, body, headers}})
}

export function listSubmittedClinicCasesAMTResponse (doctorId) {
    const service = "enviados_amt"
    const body = {
        "idmedico": doctorId
    }
    return postData({...{service, body, headers}})
}

export function listValidatedClinicCasesResponse (doctorId) {
    const service = "validados"
    const body = {
        "idmedico": doctorId
    }
    return postData({...{service, body, headers}})
}

export function listValidatedClinicCasesAMTResponse (doctorId) {
    const service = "validados_amt"
    const body = {
        "idmedico": doctorId
    }
    return postData({...{service, body, headers}})
}

export function listRejectedClinicCasesResponse (doctorId) {
    const service = "reprovadosgp"
    const body = {
        "idmedico": doctorId
    }
    return postData({...{service, body, headers}})
}

export function listRejectedClinicCasesAMTResponse (doctorId) {
    const service = "reprovadosamt"
    const body = {
        "idmedico": doctorId
    }
    return postData({...{service, body, headers}})
}

export function listCaseAttachmentsResponse (token) {
    const service = "detalhesGP"
    const body = {
        "token": token
    }
    return postData({...{service, body, headers}})
}

export function listCaseAttachmentsAMTResponse (token) {
    const service = "detalhes_amt"
    const body = {
        "token": token
    }
    return postData({...{service, body, headers}})
}

export function listToEvaluate (login) {
    const service = "avaliar"
    const body = {
        "login": login
    }
    return postData({...{service, body, headers}})
}

export function listEvaluated (login) {
    const service = "avaliados"
    const body = {
        "login": login
    }
    return postData({...{service, body, headers}})
}