import React from "react"
import { Box, Grid, Typography } from "@mui/material"

const Terms = () => {

    return (  <> 
        <Typography className="titleAMT" textTransform="uppercase" textAlign="center" marginBottom={"16px"}>
            {"Declaração de privacidade"}
        </Typography>
        <Box className="mainBox">
            <Grid container sx={{ maxWidth: "1000px" }} gap={3}>
                <Typography  variant="subtitle1" textAlign="justify">
                    Este site é fornecido pela Merck S.A (a seguir “Merck”, “nós” ou “nosso”), uma empresa subsidiária e parte das divisões globais, subsidiárias e/ou afiliadas da Merck KGaA, com sede em Darmstadt, Alemanha, (doravante denominadas “Grupo Merck”). Para os fins previstos na legislação aplicável em proteção de dados, somos a empresa que controla o processamento de seus dados pessoais (a seguir “dados”) coletados por meio deste site. Somos uma empresa consciente da responsabilidade e confiança que você deposita em nós, por isto a Merck se encontra comprometida com o tratamento adequado de seus Dados Pessoais, de modo que a todo momento respeitará a confidencialidade da informação fornecida por você e atuará conforme as leis nacionais e internacionais aplicáveis no tema de Dados Pessoais. Tratamos a proteção e confidencialidade dos dados com seriedade e seguimos regulamentações nacionais, como a Lei Geral de Proteção de Dados Pessoais (LGPD), e internacionais, como o Regulamento Geral de Proteção de Dados (RGPD), da União Europeia. 
                    <br/>
                    Neste sentido, a Merck agradece seu interesse em se registrar e está à sua disposição para quaisquer perguntas ou comentários que você possa ter a respeito do processamento de seus Dados Pessoais. Os canais de comunicação disponíveis estão listados abaixo. Em conformidade com a Lei N° 13.709 (LGPD) e demais normas aplicáveis sobre este tema, nós disponibilizamos a presente Declaração de Privacidade.
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                    <b>1.         Responsável</b>
                        <br/>A Merck S.A., localizada na Avenida das Nações Unidas, 12.995 – 30º andar, Brooklin Paulista, São Paulo – SP, Brasil nos termos da Lei Geral de Proteção de Dados, é a Controladora dos Dados Pessoais processados neste site, sendo, portanto, a responsável pelas decisões referentes ao tratamento destes, nos termos dispostos nesta Declaração de Privacidade e nos Termos de Uso. 
                </Typography>
                <Typography  variant="subtitle1" textAlign="left">
                    <b>2.         Definições</b>
                        <br/>Buscando a transparência e facilitar sua compreensão sobre os aspectos desta Declaração, nós apresentamos as seguintes definições que serão relevantes para seu melhor entendimento sobre este tema: 
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp; <b>Dado Pessoal ou Dado:</b> é qualquer informação relativa a uma pessoa física que a identifique ou que torne possível a sua identificação.  Exemplos: Nome, RG, CPF, IP, e-mail etc.
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	<b>Dado Sensível:</b> é qualquer informação pessoal sobre os seguintes temas: origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa física
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	<b>Bases legais:</b> São todas as hipóteses previstas na Lei Geral de Proteção de Dados que autorizam a Merck a tratar seus dados pessoais: interesse legítimo, seu consentimento, cumprimento de obrigação legal, execução de contrato, entre outras.
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	<b>Encarregado ou Data Privacy Officer (DPO):</b> é a pessoa responsável na Merck Brasil pelo tema de privacidade e proteção de dados pessoais. Atua como canal de comunicação entre a Merck, os titulares de dados e a Autoridade Nacional de Proteção de Dados
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	<b>Titular dos dados:</b> é a pessoa física a quem se referem os dados pessoais que são objeto de tratamento pela Merck.
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	<b>Usuário:</b> é a pessoa física, que utilizará a plataforma
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	<b>Tratamento:</b> é toda e qualquer atividade feita utilizando os Dados Pessoais. Exemplos: coleta, armazenamento, uso, compartilhamento, transmissão, exclusão etc.
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	<b>Controlador:</b> É a Merck, pessoa jurídica a quem compete as decisões referentes ao tratamento de dados pessoais.
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	<b>Operador ou Processador:</b> São os parceiros da Merck com quem podemos compartilhar os seus Dados Pessoais para assegurar a manutenção de nossas atividades e que devem seguir estritamente as ordens da Merck. Nós sempre buscamos avaliar cuidadosamente nossos parceiros e firmar com eles obrigações contratuais que asseguram a proteção e segurança de seus Dados.
                </Typography>
                <Typography  variant="subtitle1" textAlign="left">
                    <b>3.       Finalidades de tratamento dos dados pessoais</b>
                    <br/>As finalidades para as quais a Merck e seu eventuais Operadores podem solicitar, obter, armazenar, usar e/ou transferir Dados Pessoais, entre outras, são as seguintes: 
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Disponibilizar as funcionalidades desta plataforma digital para o Usuário
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Cadastro do Usuário, por meio desta plataforma digital, permitindo a submissão dos casos clínicos. 
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Executar tarefas de atualização, manutenção, gerenciamento, administração e/ou reparo do banco de dados e/ou desta plataforma digital, entre outras que sejam necessárias para a manutenção do devido funcionamento desta plataforma digital
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Garantir o direito de acesso do Usuário quando especificamente solicitado 
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Dar a conhecer ao Usuário, por meio dos canais de comunicação disponíveis, informação relacionada aos produtos e/ou serviços desenvolvidos pela Merck
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Criação de estatísticas agregadas, sem a identificação de um titular de dados específicos, para melhoria e desenvolvimento de nossas plataformas digitais.
                </Typography>
                <Typography  variant="subtitle1" textAlign="left">
                    <b>4.       Quais categorias de dados são tratadas?</b>
                        <br/>A Merck para atingir as finalidades descritas anteriormente nesta Declaração, coletará dados pessoais do Usuário e dos demais titulares de dados, para o devido funcionamento dos recursos disponíveis e para efetivar o cadastro nesta plataforma digital.
                        Portanto será disponibilizada esta Declaração de Privacidade para que o Usuário entenda como será feito o tratamento das categorias de dados pessoais de identificação, contato e informações profissionais, sendo os principais dados pessoais os seguintes: 
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Nome e sobrenome do Usuário. 
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Número de registro no Conselho Regional de Medicina 
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Instituição de trabalho
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Cidade, Estado, UF
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;	Canais de contato: como e-mail e contato telefônico.
                        Além disso, podemos coletar e tratar eventuais dados transacionais referentes ao seu login em nossa plataforma digital. Estes dados contemplam o IP, data e duração da visita, bem como demais dados de sua atividade em nossa plataforma digital.
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                    <b>5.       Armazenamento de dados</b>
                        <br/>A Merck armazena Dados Pessoais pelo período necessário para executar o serviço para o qual os dados foram coletados e a finalidade para a qual foram processados nos termos e prazos aplicáveis pela legislação brasileira. Da mesma forma, a fim de proteger e garantir a segurança dos dados pessoais coletados, a Merck pode celebrar contratos com terceiros para o armazenamento ou hospedagem desses dados ou outros serviços que permitam cumprir com as finalidades desta plataforma digital.
                        Dados sem qualquer informação pessoal identificável podem ser armazenados permanentemente. 
                        </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                    <b>6.       Proteção de dados</b>
                        <br/>A Merck, em cumprimento com a legislação aplicável sobre o tema de dados pessoais, adota as medidas de segurança exigidas por lei para a proteção de seus dados; bem como exige que eventuais terceiros a quem possa transferir estas informações para tratamento ou armazenamento, também adotem as mesmas medidas de segurança. Todas as informações que você nos fornece são armazenadas em servidores seguros, e adotamos todas as devidas medidas para protegê-las. 
                        Infelizmente, a transmissão de informações pela Internet não é completamente segura. Apesar de fazermos o nosso melhor para proteger seus dados pessoais, não podemos garantir a completa segurança dos seus dados transmitidos a esta plataforma digital; qualquer transmissão será realizada ao seu próprio risco. Uma vez que tenhamos recebido suas informações pessoalmente identificáveis, nós sempre utilizaremos procedimentos estritos e recursos de segurança para prevenir acesso não autorizado.
                        A Merck se compromete a informar aos titulares de dado afetados caso haja violação de nossas medidas de segurança que incorram em perda, mau uso, alteração indevida, acesso e/ou divulgação não autorizada ou roubo dos dados pessoais por atos fora do controle da Merck, sempre que possam acarretar risco ou dano relevante aos titulares.
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                    <b>7.       Compartilhamento de dados pessoais</b>
                        <br/>Para fins de cumprimento de qualquer uma das finalidades indicadas nesta Declaração de Privacidade, a Merck pode transferir Dados Pessoais dentro do Grupo Merck e/ou a terceiros que nos prestem serviços, incluindo transferências internacionais de Dados Pessoais.
                        Poderemos compartilhar os Dados Pessoais do Usuário quando acreditarmos, de boa-fé, que seja necessário para o cumprimento de uma obrigação legal, nos termos da legislação aplicável, ou para responder a um processo legal. Também poderemos compartilhar Dados Pessoais, se necessário, para o nosso legítimo interesse, ou de terceiros, no tocante a questões de segurança nacional, cumprimento da lei, contencioso, investigação criminal, proteção da segurança de qualquer indivíduo, sempre que considerarmos que tal interesse prevalece sobre os interesses ou direitos e liberdades do Usuário.
                        Reforçamos nosso compromisso de que as transferências internacionais para empresas fora do Grupo Merck somente ocorrem caso o parceiro atenda nossos critérios e requisitos de segurança, bem como se o país de destino demonstrar um nível semelhante ou até mais rigoroso do que o previsto na legislação brasileira sobre este tema.	
                        Caso a Merck venha a sofrer fusão, aquisição ou qualquer outra reestruturação corporativa, podemos divulgar os dados pessoais tratados por meio desta plataforma digital aos terceiros envolvidos nesta transação corporativa. Qualquer divulgação só seria feita para o fim exclusivo de assegurar a continuidade dos serviços prestados (caso haja).
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                    <b>8.       Direitos do titular dos dados pessoais </b>
                        <br/>Conforme disposto na Lei Geral de Proteção de Dados Pessoais, o titular dos dados tem direito a obter da Merck, em relação a seus dados pessoais tratados por nós, a qualquer momento e mediante requisição, as seguintes informações:  confirmação da existência de tratamento; acesso aos dados; correção de dados incompletos, inexatos ou desatualizados; anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a Lei; portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial da Merck; eliminação dos dados pessoais tratados com o seu consentimento, exceto nas hipóteses de guarda legal e outras dispostas na Lei; informação das entidades públicas e privadas com as quais a Merck possa ter compartilhado seus dados; informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; revogação do consentimento, nos termos da Lei.
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                    <b>9.       Coleta de informações por terceiros. </b>
                        <br/>Esta plataforma digital eventualmente pode conter links para outros sites cujas práticas de informação podem ser diferentes das práticas da Merck. Os serviços, conteúdo, informações e/ou a administração desses sites não são de responsabilidade da Merck e, portanto, a Merck não será responsável pela proteção e tratamento dos Dados Pessoais que o Usuário fornece a terceiros.
                        É responsabilidade do Usuário consultar as condições de privacidade de terceiros responsáveis pelo tratamento que eles fornecerão aos Dados Pessoais. Da mesma forma, a Merck, em nenhum caso e sob nenhuma circunstância será responsável pelo tratamento que outros sites que não sejam esta plataforma digital forneçam aos Dados Pessoais dos titulares de dados.
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                     <b>10.       Atualização da declaração. </b>
                        <br/>A Merck se reserva ao direito de atualizar ou complementar a presente Declaração de Privacidade para adaptá-la a novidades legislativas ou jurisprudenciais, assim como a boas práticas do setor. Sempre que cabível, a Merck notificará a modificação ao Usuário, que é responsável por ler e conhecer as alterações a presente Declaração.
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                    <b>11.       Contato. </b>
                        <br/>Se você tiver dúvidas em relação a seus Dados Pessoais, encaminhe-as ao nosso Encarregado de Proteção de Dados Pessoais, que foi especialmente designado pela Merck, para atender a solicitações feitas em relação ao processamento de Dados Pessoais por meio de mensagem para o seguinte endereço eletrônico: dataprivacy-br@merckgroup.com ou acessar o portal da Merck para Proteção de Dados.
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                   <b>12.       Obrigações do Usuário </b>
                        <br/>Ao se registrar, usar ou navegar nesta plataforma digital, o Usuário concorda expressamente com o tratamento de seus dados pessoais pela Merck. O Usuário concorda em isentar a Merck contra qualquer possível reivindicação, multa ou penalidade que possa ser exigida como resultado da violação pelo Usuário desta Declaração de Privacidade. 
                        A Merck reserva-se ao direito de limitar, suspender ou encerrar o acesso a esta plataforma digital, caso o Usuário NÃO cumpra o conteúdo desta Declaração de Privacidade, os Termos de Uso ou quaisquer outros termos ou condições específicas contidas nesta plataforma digital. O referido direito também pode ser exercido no caso de a Merck suspeitar razoavelmente de que o Usuário está violando qualquer um dos conteúdos mencionados acima.
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                    <b>13.       Comunicações de dados de terceiros realizadas pelo usuário.</b>
                        <br/>O Usuário está ciente de que, ao usar as diferentes funcionalidades que compõem esta plataforma digital, elas poderão eventualmente incorporar dados de terceiros. 
                        Nesse sentido, a Merck não se responsabiliza pelas possíveis consequências ou danos que o Usuário ou terceiros possam sofrer devido à sua decisão livre de convidar seus pacientes a compartilhar seus dados pessoais (incluindo dados pessoais relacionados a sua saúde) nesta plataforma digital. 
                        Antes de fornecer dados pessoais relacionados a terceiros nesta plataforma digital, o Usuário deve obter seu consentimento prévio e expresso, informando-os dos termos contidos nesta Declaração de Privacidade. O Usuário concorda em isentar a Merck de qualquer possível reivindicação, multa ou sanção que possa ser exigida como resultado de uma falha pelo Usuário do dever descrito neste parágrafo. 
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                    <b>14.       Segurança</b>
                        <br/>A Merck mantém os níveis de segurança da proteção de dados pessoais de acordo com a legislação nacional aplicável sobre o tema, bem como de acordo com padrões nacionais e internacionais.
                </Typography>
                <Typography  variant="subtitle1" textAlign="justify">
                    <b>15.       Nulidade e ineficiência das cláusulas</b>
                        <br/>Se qualquer cláusula incluída nesta Declaração de Privacidade for declarada total ou parcialmente nula ou ineficaz, tal nulidade ou ineficácia afetará apenas a referida disposição ou a parte dela que é nula ou ineficaz, subsistindo nesta Declaração de Privacidade em todo o resto e considerando tal provisão total ou parcialmente como não incluída.
                        Atualização: Agosto 2024
                </Typography>
            </Grid>
        </Box>
        </> 
    )
}
export default Terms
