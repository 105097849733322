import React, { useCallback, useState } from "react"
import { Alert, Box, Button, Checkbox, CircularProgress, FormControl, Grid, Link, Paper, Snackbar, TextField, Typography } from "@mui/material"
import { submitCaseFiles } from "../data/ingester"
import debounce from "debounce"
import { CheckCircle } from "@mui/icons-material"
import { disclaimer } from "../utils/constants"
// import { useNavigate } from "react-router-dom"

const SubmitFiles = ({doctorId, link}) => {
    const [snackStatus, setSnackStatus] = useState({open:false, severity:"", msg: "" })
    const [disableButton, setDisableButton] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState({})
    const [errorMessage, setErrorMessage] = useState("")
    // const navigate =  useNavigate()

    const handleSnackClose = () => {
        setSnackStatus({open:false})
    }

    const sendFiles = async (filesWithIds) => {
        setLoading(true)
        const formData = new FormData()
        formData.append('idmedico', doctorId.toString())
        formData.append('link', link)
        formData.append('ativo', 'false')

        filesWithIds.forEach(({ file, id }) => {
            formData.append('file', file)
            const incrementedId = (parseInt(id, 10) + 1).toString()  // Adiciona o ID do arquivo, incrementando o valor numérico em 1
            formData.append('file_id', incrementedId)
        })
        const resp = await submitCaseFiles({...{formData}})
        if(!resp) {
            setSnackStatus({open:true, severity:"error", msg: "Erro: Operação não realizada." })
            setDisableButton(false)
        } else {
            setSuccess(true)
        }
        setLoading(false)
    }

    const handleSubmit = () => {
        const filesWithIds = Object.values(selectedFiles).filter(fileObj => fileObj.file != null)
        const fileCount = filesWithIds.length

        if (fileCount === 0 || fileCount > 2) {
            setErrorMessage('Selecione ao menos 1 e no máximo 2 arquivos.')
            setDisableButton(false)
        } else if (filesWithIds.findIndex((item) => item.file?.type != "application/pdf") >= 0) { 
            setErrorMessage('Apenas arquivos PDF são aceitos.')
            setDisableButton(false)
        } else {
            setErrorMessage('')
            sendFiles(filesWithIds)
        }
    }

    const handleDebounceLink = debounce(handleSubmit, 300)
    const handleCallBackSubmission = useCallback(()=>{
        setDisableButton(true)
        handleDebounceLink()
    }, [selectedFiles, doctorId, link])

    const handleFileChange = (key, event) => {
        setErrorMessage('')
        const file = event.target.files ? event.target.files[0] : null
        setSelectedFiles(prevState => ({
            ...prevState,
            [key]: { file, id: key }
        }))
    }

    const handleCheck = (event) => {
        setIsChecked( event.target.checked)
    }

    // const onClose = ()=> {
    //     navigate('/')
    // }

    const categories = [
        {
            title: "Categoria 1 - CASOS DE PACIENTES DESAFIADORES",
            description: "Ex: Em Uso de Bavencio® no tratamento de manutenção de 1ª linha no carcinoma urotelial (UC) localmente avançado ou metastático ou que já esteve em uso de Bavencio® no tratamento de manutenção de 1ª linha no carcinoma urotelial (UC) localmente avançado ou metastático em pacientes clinicamente desafiadores como, por exemplo: idade muito avançada, função renal comprometida, inelegíveis à cisplatina ou presença de outras comorbidades."
        },
        {
            title: "Categoria 2 - QUALIDADE DE VIDA MANTIDA E ESTENDIDA",
            description: "Ex: Uso de Bavencio® no tratamento de manutenção de 1ª linha no carcinoma urotelial (UC) localmente avançado ou metastático demonstrando uma melhora na qualidade de vida dos pacientes, com redução dos sintomas e ou melhora/estabilidade clínica do status de performance."
        },
        {
            title: "Categoria 3 - Sobrevida Global Estendida: Segurança E Eficácia De Longo Prazo",
            description: "Ex: Uso de Bavencio® no tratamento de manutenção de 1ª linha no carcinoma urotelial (UC) localmente avançado ou metastático mantendo resposta em longo prazo alinhado a um perfil de segurança manejável."
        },
        
    ]
    const DescComp = ({title, description}) => {
        return (
            <Box sx={{ml:"32px"}}>
                <Typography className="titleAMT" textTransform="uppercase">
                    {title}
                </Typography>
                <Typography  variant="body1" textAlign="justify">
                    {description}
                </Typography>
            </Box>
        )
    }
    return (    
        <Box className="mainBox">
            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                autoHideDuration={6000}
                open={snackStatus?.open}
                onClose={handleSnackClose}
            >
                <Alert
                    onClose={handleSnackClose}
                    severity={snackStatus?.severity}
                    variant="standard"
                    sx={{ width: '100%', p:2, textTransform:"uppercase"}}
                >
                    {snackStatus?.msg}
                </Alert>
            </Snackbar>
            <Box className="loginLogo"/>
            {!success &&
                <Grid container sx={{ width: "100%", justifyContent:"center" }} gap={4}>
                    {categories.map((category, index)=>{
                        return (
                        <Grid key={index} container item xs={12}>
                            <Grid item md={4}>
                                <FormControl sx={{width:"100%", height:"100%"}}>
                                    <TextField type="file" name="pdf1" accept=".pdf"
                                        disabled={success} 
                                        onChange={event => handleFileChange( index, event)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={8} >
                                <DescComp {...{title: category.title, description: category.description}}/> 
                            </Grid>
                        </Grid>
                        )
                    })}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                            <Checkbox checked={isChecked} onChange={(event)=>handleCheck(event)} />
                            <Typography className="privacylink" >
                                {"Ao clicar em enviar declaro que li e concordo com o "}
                            </Typography>
                            <Link className="privacylink" href="/terms" target="_blank" rel="noopener" >
                                {" Aviso de Privacidade do Concurso de Casos Clínicos Merck"}
                            </Link>
                        </Box>
                    </Grid>
                    
                    <Box sx={{mb:"150px"}}>
                        {errorMessage.length > 0 &&
                            <Paper className="errorPaper" elevation={0}>{errorMessage}</Paper> 
                        }
                        {!loading && !success &&
                        <Button className="avaliateButtonAMT loginItem" disabled={!isChecked || disableButton} onClick={handleCallBackSubmission} >
                            <Typography variant="body2" >
                                {"Enviar"}
                            </Typography>
                        </Button>
                        }
                        {loading &&
                            <>
                            <Typography className="titleAMT">
                                {"Aguarde mais alguns instantes, seu(s) Caso(s) Clínicos estão sendo enviados ..."}
                            </Typography>
                            <Box sx={{width:"100%", textAlign:"center" }}>
                                <CircularProgress/>
                            </Box>
                            </>
                        }
                        {success &&
                        <Paper className="successPaper" elevation={0}>
                            <Typography variant="body2" >
                                {"ARQUIVOS ENVIADOS!"}
                            </Typography>
                            <CheckCircle fontSize="large"/>
                        </Paper> 
                        }  
                    </Box>
                    <Paper className="disclaimer" elevation={0}>
                        {disclaimer}
                    </Paper> 
                </Grid>
            }
            {success &&
            <Grid container sx={{justifyContent:"center" }}>
                <Paper className="successSinglePaper" elevation={0}>
                    <Typography className="titleAMT" variant="h5">
                        {"Os casos clínicos foram enviados com sucesso!"}
                    </Typography>
                    {/* <Button className="avaliateButtonAMT loginItem" onClick={()=>onClose()} >
                    <Typography variant="body2" textTransform="uppercase" >
                        {"Clique aqui para sair"}
                    </Typography>
                    </Button> */}
                </Paper> 
            </Grid>
            }
        </Box>
    )
}
export default SubmitFiles
