import React from 'react'
import { AuthContext } from './AuthContext'
import { useUser } from './useUser'

const AuthProvider = ({children }) => {
    const { loggedUser, signIn, signOut } = useUser()

    return (
        <AuthContext.Provider value={[loggedUser, signIn, signOut]}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider