import React , { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import AppRoutes from './routes'
import { Box } from '@mui/material'
import AppBar from './components/AppBar'
import Bottom from './components/Bottom'
import AuthProvider from './context/AuthProvider'

const rootElement = document.getElementById('root')

const root = createRoot(rootElement)
root.render(
    // <StrictMode>
        <AuthProvider>
            <AppBar/>
            <Box sx={{ p:{xs:"32px 8% 50px 8%"}}}>
                <AppRoutes />
            </Box>
            <Bottom/>
        </AuthProvider>
    // </StrictMode>
)
