import React, {useEffect, useState} from "react"
import { Box, Grid, Paper } from "@mui/material"
import { ListClinicCases, NavTab } from "../components"
import { useLocation } from "react-router-dom"
import { disclaimer } from "../utils/constants"

export default function List() {
    const location = useLocation()
    const [currentIndexTab, setCurrentIndexTab] = useState(location?.state?.indexTab || 0)
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState()

    useEffect(() => {
        // TODO - CATEGORIAS DEVEM VIR DO BE
        const categories= [
            {
                name: "todos",  
            },
            {
                name: "categoria1",
                categoria: 1    
            },
            {
                name: "categoria2",
                categoria: 2    
            },
            {
                name: "categoria3",
                categoria: 3    
            },
            {
                name: "avaliados",
                onlyEvaluated: true  
            }
        ]
 
        setCategories(categories)
        setCategory(categories[currentIndexTab])
    }, [])

    const handleChange = (_, newIndexTab) => {
        setCurrentIndexTab(newIndexTab)
        setCategory(categories[newIndexTab])
    }
    
    return (<>
            <Box sx={{mb:"150px"}}>
                <NavTab {...{handleChange, categories, currentIndexTab, setCurrentIndexTab}} />
                <Grid  sx={{ mt:2,display:"flex"}}>
                    <ListClinicCases {...{currentIndexTab, categories, category}}/> 
                </Grid>
            </Box>
            <Box className="mainBox">
                <Paper sx={{ width: "100%"}} className="disclaimer" elevation={0}>
                    {disclaimer}
                </Paper> 
            </Box>
            </>
    )
}
