import React, { useState } from "react"
import { Box, Button, CircularProgress, Dialog, DialogTitle, Grid, IconButton, Link, Paper, Tab, Tabs, Typography } from "@mui/material"
import OutlinedInput from '@mui/material/OutlinedInput'
import { checkCredentials } from "../data/retriever"
import { useAuth } from "../context/useAuth"
import ChangePsswd from "../components/ChangePsswd"

const Login = () => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [objUser, setObjUser] = useState()
    const {signIn} = useAuth()

    const authenticate = async () => {
        setLoading(true)
        setErrorMessage("Credenciais inválidas!")
        if(username && password) {
            const objLogin = await checkCredentials({...{username, password}})
            if(objLogin){
                setErrorMessage("")
                if(objLogin.reset == 1){
                    setObjUser(objLogin)
                    handleOpenCloseDialog()
                } else {
                    signIn(objLogin)
                }
            }
        }
        setLoading(false)
    }
    const handleName = (event) => {
        setUsername(event.target.value)
    }
    const handlePassword = (event) => {
        setPassword(event.target.value)
    }
    const handleOpenCloseDialog = () => {
        setOpen(!open)
        if(open){
            signIn(objUser)
        }
    }

    return (
    <Box className="loginBox">
        <Dialog fullWidth maxWidth="md" onClose={handleOpenCloseDialog} open={open}>
            <DialogTitle sx={{display:"flex", justifyContent:"right", alignItems:"center"}}>
                <>
                <Link variant="body1" className="remindmelink" onClick={handleOpenCloseDialog}>
                {"Lembrar-me depois"}
                </Link>
                </>
            </DialogTitle>
            <ChangePsswd user={username} closeMainDialog={handleOpenCloseDialog}/>
        </Dialog>
        <Box className="loginLogo"/>
        <Grid container className="login">
            <Box className="loginItem">   
                <Typography sx={{fontWeight:"bold", alignSelf:"center"}} >
                    {"Email"}
                </Typography>
                <OutlinedInput name="login" onChange={handleName} className="avaliateButton" autoFocus={true} />
            </Box> 
            <Box className="loginItem"> 
                <Typography sx={{fontWeight:"bold", alignSelf:"center"}} >
                    {"Senha"}
                </Typography>
                <OutlinedInput 
                    name="password"
                    onChange={handlePassword} 
                    className="avaliateButton" 
                    type="password"
                    onKeyUp={(event) => {if(event.key == "Enter") authenticate()}} 
                    />
            </Box>
            <Box className="loginItem"> 
                {!loading &&
                <>
                    {errorMessage.length > 0 &&
                        <Paper className="errorPaper" elevation={0}>{errorMessage}</Paper> 
                    }
                    <Button  onClick={authenticate} sx={{width:"100%"}}>
                        <Typography variant="body2" textTransform="uppercase" >
                        {"Acessar"}           
                        </Typography>
                    </Button>
                    <Link href="/recover">
                        {'Esqueci minha senha'}
                    </Link>
                </>
                }
                {loading &&
                    <Box className="progress" visibility="visible">
                        <CircularProgress />
                    </Box>
                }
            </Box>
        </Grid> 
    </Box> 
    )
}
export default Login
