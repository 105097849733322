import { useEffect, useState } from "react"
import { useLocalStorage } from "./useLocalStorage"

export const useUser = () => {

  const [loggedUser, setLoggedUser] = useState()
  const { setItem, getItem, removeItem } = useLocalStorage()
  
  useEffect(() => {
          const user = getItem("user")
          if (user) {
              setLoggedUser(JSON.parse(user))
          } else {
            setLoggedUser({})
          }
  }, [])

  const signIn = (user) => {
      setItem("user", JSON.stringify(user))
      setLoggedUser(user)
  }
  const signOut = (user) => {
      removeItem("user")
      setLoggedUser({})
  }
  return { loggedUser, signIn, signOut }
}