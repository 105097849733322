import React from "react"
import { Box } from "@mui/material"
import { useAuth } from "../context/useAuth"

const Bottom = () => {
    const { loggedUser } = useAuth()

    return <>
        {loggedUser &&
            <Box className={`bottom ${Object.keys(loggedUser).length > 0 ? "defaultBottom" : "loginBottom"}`} /> 
        }
        </>
}
export default Bottom
