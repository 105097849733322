import { createTheme } from '@mui/material'
import { ptBR } from '@mui/material/locale'

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1400,
            xl: 1920
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableFocusRipple: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                sizeSmall: {
                    padding: '6px 16px'
                },
                sizeMedium: {
                    padding: '8px 20px'
                },
                sizeLarge: {
                    padding: '11px 24px'
                },
                textSizeSmall: {
                    padding: '7px 12px'
                },
                textSizeMedium: {
                    padding: '14px 16px'
                },
                textSizeLarge: {
                    padding: '12px 16px'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root:{
                    fontSize: 16,
                    backgroundColor: '#FFFFFF',
                    // '&:before,&:after': {
                    //     border: 'none  !important',
                    //     backgroundColor: 'transparent',
                    // },
                  //   '&:hover': {
                  //       border: 'none !important',
                  //       backgroundColor: 'transparent',
                  //   },
                  //   '.borderInputFull&:hover,.borderSelect&:hover': {
                  //     backgroundColor: '#E4E4E4',
                  //     border: '1px solid !important',
                  //   },
                    '&.Mui-focused .MuiInputBase-input': {
                        backgroundColor: 'transparent',
                        border: 'none',
                    },
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: { 
                    paddingRight: 0,
                    height:50,
                    maxHeight:50,
                    width:"auto",
                    textWrap:"nowrap",
                    border: '2px solid #B0C5D3',
                    '& .MuiTabs-scroller .MuiButtonBase-root':{
                        maxWidth:280,
                        marginInline:10,
                        gap:5,
                        textTransform:"uppercase",
                        backgroundColor:"transparent",
                        width:"auto",
                        color:"inherit"
                    },
                    '& .MuiTabs-scroller .MuiButtonBase-root.Mui-selected':{
                        color:"rgb(93, 188, 202) !important"
                    },
                    '& .MuiButtonBase-root:hover':{
                        color:"#4C338C"
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root:{   
                    backgroundColor:"transparent !important",
                    color:"rgb(93, 188, 202) !important",   
                    '&:hover': {
                        backgroundColor:"transparent !important",
                        color:"rgb(93, 188, 202) !important"
                    },
                    '&.Mui-disabled':{
                        backgroundColor:"transparent !important",
                        color:"rgba(93, 188, 202, 0.26) !important"
                    }
                }
            }
        },
        MuiAccordionSummary:{
            styleOverrides:{
                root:{
                    '&.Mui-expanded':{
                        minHeight: 0,
                    },
                    ':hover':{
                        backgroundColor: "transparent !important",
                        color: "rgb(60, 60, 60) !important",
                    } 
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: false,
            },
            styleOverrides: {
                root:{      
                    '&.MuiButton-root':{
                        backgroundColor:'rgb(93, 188, 202) !important',
                        color:"#FFFFFF !important",
                    },
                    '&:hover':{
                        backgroundColor:'rgb(81, 164, 177) !important',
                        color:"#FFFFFF !important",
                    },
                    '&.tabMenu:hover':{
                        backgroundColor:'transparent !important',
                        color: "rgb(93, 188, 202) !important",
                    },
                    '&.Mui-disabled':{
                        backgroundColor:'rgba(93, 188, 202, 0.26) !important',
                        color:"#FFFFFF !important",
                    },
                    '&.reject':{
                        backgroundColor:"#D14343 !important",
                    },
                    '&.reject&:hover':{
                        backgroundColor:"#B14343 !important",
                    },
                    '&.adminButton, &.adminButton&:hover':{
                        color:"#FFFFFF !important",
                        backgroundColor:"rgb(237, 50, 136) !important",
                        textTransform:"uppercase"
                    },
                    '&.avaliateButtonAMT, &.avaliateButtonAMT&:hover':{
                        color:"#FFFFFF !important",
                        backgroundColor:"rgb(81, 164, 177) !important",
                        textTransform:"uppercase"
                    },
                    '&.avaliateButtonAMT.Mui-disabled':{
                        backgroundColor:"rgb(81, 164, 177, 0.26) !important",
                    },
                    '&.avaliateButton, &.avaliateButton&:hover':{
                        color:"#FFFFFF !important",
                        backgroundColor:"#4C338C !important",
                        textTransform:"uppercase"
                    },
                    '&.avaliateButton.Mui-disabled':{
                        backgroundColor:"#4C338C38 !important",
                    }
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root:{
                    '& .MuiSlider-valueLabel':{
                        color: "#4C338C",
                        backgroundColor:"transparent"
                    }
                }
            }
        },

        MuiTable: {
            styleOverrides: {
                root:{
                    boxShadow: 2,
                    border: '2px solid #B0C5D3',
                    "& .MuiTableCell-root:focus-within, & .MuiTableCell-root:focus": {
                    outline: "none"
                    },
                    "& .MuiTableBody-root .MuiTableCell-root": {
                        fontSize: '16px',
                        color:'#333333',
                        borderBottom: '1px solid #B0C5D3',
                    },
                    '& .MuiTableBody-root .MuiTableRow-root:hover': {
                        backgroundColor:"#F3F4F6"
                    },
                    "& .MuiButton-root, .MuiButton-root:hover":{
                        color:"#FFFFFF",
                        backgroundColor:"#4C338C",
                        textDecoration:"none",
                        fontSize: '16px',
                        padding:10
                    },
                    '&.accordionTable .MuiTableRow-root:hover': {
                        backgroundColor:"transparent",
                    },
                    "&.accordionTable  .MuiTableCell-root": {
                        border: '0px',
                    },
                    // '&.accordionTable .MuiTableRow-root': {
                    //     backgroundColor:"#F7F7F7"
                    // },
                    '& .MuiTableCell-root': {
                        textWrap:"wrap",
                        // maxWidth:"250px !important"
                    }        
                }
            }
          },
        MuiTablePagination: {
            styleOverrides: {
                root:{
                    "& .MuiTablePagination-selectLabel":{
                        position:"absolute",
                        left:"16px",
                        width:"120px"
                    },
                    "& .MuiInputBase-root":{
                        position:"absolute",
                        left:"130px"
                    },
                    "& .MuiTablePagination-actions .Mui-disabled":{
                        backgroundColor:"transparent !important",
                        color: "#F7F7F7 !important"
                    }          
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box',
                    margin: 0,
                    padding: 0
                },
                html: {
                    MozOsxFontSmoothing: 'grayscale',
                    WebkitFontSmoothing: 'antialiased',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    width: '100%'
                },
                body: {
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                    minHeight: '100%',
                    width: '100%'
                },
                '#__next': {
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%'
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                    '& .MuiTableCell-root': {
                        fontWeight: 700,
                        lineHeight: 1,
                        letterSpacing: 0.5,
                        textTransform: 'uppercase',
                        fontSize: '14px',
                        color:'#000000',
                        borderBottom: '2px solid #B0C5D3'
                    },
                    '& .MuiTableCell-paddingCheckbox': {
                        paddingTop: 4,
                        paddingBottom: 4
                    }
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root:{
                    margin: "16px",
                    color: "#FFFFFF"
                }
            }
        },
    },
    palette: {
        neutral: {
            100: '#F3F4F6',
            200: '#E5E7EB',
            300: '#D1D5DB',
            400: '#9CA3AF',
            500: '#6B7280',
            600: '#4B5563',
            700: '#374151',
            800: '#1F2937',
            900: '#111827'
        },
        action: {
            active: '#6B7280',
            focus: 'rgba(55, 65, 81, 0.12)',
            hover: 'rgba(55, 65, 81, 0.04)',
            selected: 'rgba(55, 65, 81, 0.08)',
            disabledBackground: 'rgba(55, 65, 81, 0.12)',
            disabled: 'rgba(55, 65, 81, 0.26)'
        },
        background: {
            default: '#F7F7F7',
            paper: '#FFFFFF'
        },
        divider: '#E6E8F0',
        primary: {
            // main: '#4C338C',
            main: 'rgb(93, 188, 202)',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#FFEDE5',
            contrastText: '#FFFFFF'
        },
        success: {
            main: '#14B8A6',
            light: '#43C6B7',
            dark: '#0E8074',
            contrastText: '#FFFFFF'
        },
        info: {
            main: '#2196F3',
            light: '#64B6F7',
            dark: '#0B79D0',
            contrastText: '#FFFFFF'
        },
        warning: {
            main: '#FFB020',
            light: '#FFBF4C',
            dark: '#B27B16',
            contrastText: '#FFFFFF'
        },
        error: {
            main: '#D14343',
            light: '#DA6868',
            dark: '#922E2E',
            contrastText: '#FFFFFF'
        },
        text: {
            primary: 'rgb(60, 60, 60)',
            secondary: '#4C338C',
            // secondary: '#65748B',
            disabled: 'rgba(55, 65, 81, 0.48)'
        }
    },
    shape: {
        borderRadius: 8
    },
    shadows: [
        'none',
        '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
        '0px 1px 2px rgba(100, 116, 139, 0.12)',
        '0px 1px 4px rgba(100, 116, 139, 0.12)',
        '0px 1px 5px rgba(100, 116, 139, 0.12)',
        '0px 1px 6px rgba(100, 116, 139, 0.12)',
        '0px 2px 6px rgba(100, 116, 139, 0.12)',
        '0px 3px 6px rgba(100, 116, 139, 0.12)',
        '0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
        '0px 5px 12px rgba(100, 116, 139, 0.12)',
        '0px 5px 14px rgba(100, 116, 139, 0.12)',
        '0px 5px 15px rgba(100, 116, 139, 0.12)',
        '0px 6px 15px rgba(100, 116, 139, 0.12)',
        '0px 7px 15px rgba(100, 116, 139, 0.12)',
        '0px 8px 15px rgba(100, 116, 139, 0.12)',
        '0px 9px 15px rgba(100, 116, 139, 0.12)',
        '0px 10px 15px rgba(100, 116, 139, 0.12)',
        '0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
        '0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
        '0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
        '0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)'
    ],
    typography: {
        fontFamily: 'Arial, Helvetica,  sans-serif',
        button: {
            fontWeight: 600,
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.6,
            fontFamily: 'Arial, Helvetica,  sans-serif'

        },
        body2: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '16px',
            fontFamily: 'Arial, Helvetica,  sans-serif'
        },
        subtitle1: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.5
        },
        subtitle2: {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '16px'
        },
        overline: {
            fontSize: '0.75rem',
            fontWeight: 600,
            letterSpacing: '0.5px',
            lineHeight: 2.5,
            textTransform: 'uppercase'
        },
        caption: {
            fontSize: '0.55rem',
            fontWeight: 400,
            lineHeight: 1.66
        },
        h1: {
            fontWeight: 700,
            fontSize: '3.5rem',
            lineHeight: 1.375
        },
        h2: {
            fontWeight: 700,
            fontSize: '3rem',
            lineHeight: 1.375
        },
        h3: {
            fontWeight: 400,
            fontSize: '48px',
            lineHeight: 1.375
        },
        h4: {
            fontWeight: 700,
            fontSize: '2rem',
            lineHeight: 1.375
        },
        h5: {
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: 1.375
        },
        h6: {
            fontWeight: 600,
            fontSize: '1.125rem',
            lineHeight: 1.375
        }
    }
}, ptBR)