import React, { useEffect, useState } from "react"
import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import { exportExcel } from "../utils/functions"
import { FileDownload } from "@mui/icons-material"
import { getCasesDashboard, getEvalCasesDashboard } from "../data/retriever"

const Admin = () => {
    const [loading, setLoading] = useState(false)
    const [casesDash, setCasesDash] = useState("")
    const [evalCasesDash, setEvalCasesDash] = useState("")
    const [amountSent, setAmountSent] = useState(0)
    const [amountGP, setAmountGP] = useState(0)
    const [appGP, setAppGP] = useState(0)
    const [rejGP, setRejGP] = useState(0)
    const [appAMT, setAppAMT] = useState(0)
    const [rejAMT, setRejAMT] = useState(0)
    const [amountAMT, setAmountAMT] = useState(0)
    const [amountEval, setAmountEval] = useState(0)

    const getCasesDash= async () => {
        setLoading(true)
        const cases= await getCasesDashboard()
        const evalCases= await getEvalCasesDashboard()

        setCasesDash(cases)
        setEvalCasesDash(evalCases)

        const casesGP = cases?.filter((item)=> item.aprovado_gp != null)
        setAppGP(casesGP?.filter((item)=> item.aprovado_gp == 1).length)
        setRejGP(casesGP?.filter((item)=> item.aprovado_gp == 0).length)

        const casesAMT = cases?.filter((item)=> item.aprovado_amt != null)
        setAppAMT(casesAMT?.filter((item)=> item.aprovado_amt == 1).length)
        setRejAMT(casesAMT?.filter((item)=> item.aprovado_amt == 0).length)

        setAmountSent(cases.length)
        setAmountGP(casesGP.length)
        setAmountAMT(casesAMT.length)

        const uniqueEvalCases = evalCases?.filter((ccase, index) => 
            index == evalCases.findIndex((item) => 
                item.idAnexo == ccase.idAnexo
            )
        )
        setAmountEval(uniqueEvalCases.length || 0)
        setLoading(false)
    }

    useEffect(() => {
        getCasesDash()
    }, [])

    const DescComp = ({title, value, totalValue, obs}) => {
        return (
            <Box padding={2}>
                <Typography variant="body2" textTransform="uppercase">
                    {title}
                </Typography>
                <Typography variant="h5" className="valueAdmin" >
                    {value}
                    {totalValue && 
                    <>
                    <Typography variant="h6" className="totalValueAdmin">
                        {` / ${totalValue}`}
                    </Typography>
                    </>
                    }
                </Typography>
                <Typography sx={{minHeight:"25px"}} variant="subtitle1" className="totalValueAdmin">
                    {obs}
                </Typography>
                <Divider sx={{borderColor:"pink"}}/>
            </Box>
        )
    }
    return (    
        <Box className="mainBox admin">
            <Grid container item sm={12}>
                <Typography variant="h6" textTransform="uppercase" >
                    {"Casos clínicos"}
                </Typography>
            </Grid>
            {loading && 
                <Grid container   >

                    <Grid item xs={12} >
                    <div className="skeleton skeleton-header"></div>
                    <div className="skeleton skeleton-line"></div>
                    <div className="skeleton skeleton-line"></div>
                    <div className="skeleton skeleton-line"></div>
                    <div className="skeleton skeleton-line"></div>
                    <Typography
                        variant="h6"
                        color="gray"
                        >
                        Buscando dados...
                    </Typography>
                    </Grid>
                    
                </Grid>
            }
            {!loading && 
            <>
                <Grid container >
                    <Grid  item sm={6} >
                        <DescComp {...{title: "Total submetidos", value: amountSent}}/> 
                    </Grid>
                    <Grid  item sm={6} >
                        <DescComp {...{
                            title: "Total Validados - Gerente Projeto", 
                            value: amountGP, 
                            totalValue: amountSent,
                            obs: `[ ${appGP} APROVADOS - ${rejGP} REJEITADOS ]`
                            }}/> 
                    </Grid>
                
                    <Grid item sm={6} >
                        <DescComp {...{
                            title: "Total Validados - Área Médica", 
                            value: amountAMT, 
                            totalValue: appGP,
                            obs: `[ ${appAMT} APROVADOS - ${rejAMT} REJEITADOS ]`
                            }}/> 
                    </Grid>
                    <Grid item sm={6} >
                        <DescComp {...{title: "Total Avaliados com Notas - Avaliadores", value: amountEval, totalValue: appAMT}}/> 
                    </Grid>
                    <Button className="adminButton" 
                        onClick={()=>{
                            exportExcel({casesDash, evalCasesDash})
                        }} 
                        sx={{gap:1}}
                        >
                        <FileDownload fontSize="small"/>
                        <Typography variant="body2">
                        {"Download Excel"}           
                        </Typography>
                    </Button> 
                </Grid>
            </>}
            {/* <Grid container item sm={12}>
                <Typography variant="h6" textTransform="uppercase" >
                    {"Ranking - Notas"}
                </Typography>
            </Grid>   */}

                 
        </Box>
    )
}
export default Admin
