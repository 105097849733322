import React, { useState } from "react"
import { Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { CropSquare, HomeRounded, Logout, SquareRounded, StopRounded } from "@mui/icons-material"
import { useAuth } from "../context/useAuth"

const AppBar = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorAcc, setAnchorAcc] = useState(null)
    const openEl = Boolean(anchorEl)
    const openAcc = Boolean(anchorAcc)
    const { loggedUser, signOut } = useAuth()

    const handleClickEl = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClickAccount = (event) => {
        setAnchorAcc(event.currentTarget)
    }
    const handleCloseEl = () => {
        setAnchorEl(null)
    }
    const handleCloseAcc = () => {
        setAnchorAcc(null)
    }
    const handleLogout = () => {
        signOut()
        window.location.replace("/")
    }
    const handleHome = () => {
        window.location.replace("/")
    }

    const ManagerMenu = () => {
        return(
            <>
            <MenuItem component={"a"} href={"/generateLink"} onClick={handleCloseEl}>Gerar Link</MenuItem>
            <MenuItem component={"a"} href={"/search"} onClick={handleCloseEl}>Casos clínicos</MenuItem>
            <MenuItem component={"a"} href={"/evaluators"} onClick={handleCloseEl} >Avaliadores</MenuItem>
            <MenuItem component={"a"} href={"/admin"} onClick={handleCloseEl} >Admin</MenuItem>
            </>
        )
    }
    const EvaluatorMenu = () => {
        return(
            <MenuItem component={"a"} href={"/list"} onClick={handleCloseEl}>Casos clínicos</MenuItem>
        )
    }

    const AMTMenu = () => {
        return(
            <>
            <MenuItem component={"a"} href={"/search"} onClick={handleCloseEl}>Casos clínicos</MenuItem>
            <MenuItem component={"a"} href={"/admin"} onClick={handleCloseEl} >Admin</MenuItem>
            </>
        )
    }
    const MKTMenu = () => {
        return(
            <MenuItem component={"a"} href={"/admin"} onClick={handleCloseEl}>Admin</MenuItem>
        )
    }

    const objDescription = {
        "GP": "GERENTE DE PROJETO",
        "AMT": "ÁREA MÉDICA TÉCNICA",
        "AV": "AVALIADOR",
        "MKT": "ADMINISTRADOR",
    }
    const objMenu = {
        "GP": <ManagerMenu/>,
        "AMT": <AMTMenu/>,
        "AV": <EvaluatorMenu/>,
        "MKT": <MKTMenu/>
    }

    return (
        <Box className="topContent">
            {loggedUser &&
            <>
                <Box className={Object.keys(loggedUser).length > 0 ? "appBar" : "loginAppBar"} />
                {Object.keys(loggedUser).length > 0 &&
                <>
                <Box className="topMenu">
                    <IconButton onClick={handleHome} title="Home" sx={{ color:"rgb(237, 50, 136)" }} >
                        <HomeRounded fontSize="small" />
                    </IconButton>
                    <Button
                        onClick={handleClickEl}
                    >
                        <Typography variant="overline" className="titleAMT">
                            {objDescription[loggedUser?.profile]}
                        </Typography>
                    </Button>
                    <StopRounded fontSize="small" sx={{alignSelf:"center"}}/>
                    <Button
                        onClick={handleClickAccount}
                    >
                        <Typography variant="overline" className="titleAMT">
                            {"Minha Conta"}
                        </Typography>
                    </Button>
                    <IconButton onClick={handleLogout} title="Sair" >
                        <Logout fontSize="small" />
                    </IconButton>
                    {
                        objMenu[loggedUser?.profile] &&
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openEl}
                            onClose={handleCloseEl}
                        >
                            {objMenu[loggedUser?.profile] }
                        </Menu>
                    }
                    <Menu
                        id="account-menu"
                        anchorEl={anchorAcc}
                        open={openAcc}
                        onClose={handleCloseAcc}
                    >
                        <MenuItem component={"a"} href={"/changepswd"} onClick={handleCloseAcc}>Mudar senha</MenuItem>
                        {/* <MenuItem component={"a"} href={"#"} 
                            onClick={()=>{
                                handleCloseAcc()
                                handleLogout()
                            }
                            }> Sair 
                            <IconButton onClick={handleLogout} title="Sair" sx={{ height: "100%", textAlign:"right" }} >
                                <Logout fontSize="small" />
                            </IconButton>
                        </MenuItem> */}
                    </Menu>              
                </Box>
                <Box className="loginLogo"/>
                </>
                }
            </>}
        </Box>
    )
}
export default AppBar
