import {
    CssBaseline
} from '@mui/material'

import React from 'react'
import {ThemeProvider} from '@mui/material/styles'

import {theme} from './theme/theme'

import './styles.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import List from './pages/List'
import NotFound from './pages/NotFound'
import Evaluation from './pages/Evaluation'
import Login from './pages/Login'
import RecoverPwd from './pages/RecoverPwd'
import GenerateLink from './pages/GenerateLink'
import SearchClinicCases from './pages/SearchClinicCases'
import { useAuth } from './context/useAuth'
import SearchClinicCasesAMT from './pages/SearchClinicCasesAMT'
import Admin from './pages/Admin'
import SearchEvaluators from './pages/SearchEvaluators'
import ChangePsswd from './components/ChangePsswd'

const AnonymousRoutes = () => {
    return(
        <Routes>
            <Route path='/' element={<Login/>}/>
            <Route path='/recover' element={<RecoverPwd/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    )
}
const EvaluatorRoutes = () => {
    return(
        <Routes>
            <Route path='/' element={<List/>}/>
            <Route path='/list' element={<List/>}/>
            <Route path='/evaluate' element={<Evaluation/>}/>
            <Route path='/changepswd' element={<ChangePsswd/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    )
}
const ManagerRoutes = () => {
    return(
        <Routes>
            <Route path='/' element={<SearchClinicCases/>}/>
            <Route path='/search' element={<SearchClinicCases/>}/>
            <Route path='/generateLink' element={<GenerateLink/>}/>
            <Route path='/changepswd' element={<ChangePsswd/>}/>
            <Route path='/evaluators' element={<SearchEvaluators/>}/>
            <Route path='/admin' element={<Admin/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    )
}
const AMTRoutes = () => {
    return(
        <Routes>
            <Route path='/' element={<SearchClinicCasesAMT/>}/>
            <Route path='/search' element={<SearchClinicCasesAMT/>}/>
            <Route path='/admin' element={<Admin/>}/>
            <Route path='/changepswd' element={<ChangePsswd/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    )
}
const MKTRoutes = () => {
    return(
        <Routes>
            <Route path='/' element={<Admin/>}/>
            <Route path='/admin' element={<Admin/>}/>
            <Route path='/changepswd' element={<ChangePsswd/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    )
}
const objRoutes = {
    "GP": <ManagerRoutes/>,
    "AMT": <AMTRoutes/>,
    "AV": <EvaluatorRoutes/>,
    "MKT": <MKTRoutes/>
}

const App = () => {
    const {loggedUser}= useAuth()
    return <>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                {loggedUser &&
                    <>
                    {Object.keys(loggedUser).length == 0 && 
                        <AnonymousRoutes/>
                    }
                    {objRoutes[loggedUser?.profile] || <></> } 
                    </>
                }
            </BrowserRouter>
        </ThemeProvider>  
    </>
}

export default App