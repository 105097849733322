import React, { useEffect, useState } from "react"
import _404 from "../components/404"
import { useLocation } from "react-router-dom"
import { checkValidLink } from "../data/retriever"
import SubmitFiles from "./SubmitFiles"
import Terms from "./Terms"
import { useAuth } from "../context/useAuth"

const NotFound = () => {
    const location = useLocation()
    const link = location.pathname.substring(1)
    const [redirect, setRedirect] = useState(null)
    const [notFoundLinkMsg, setNotFoundLinkMsg] = useState(null)
    const [terms, setTerms] = useState(false)
    const [redirectData, setRedirectData] = useState({doctorId: 0, link: ""})
    const { loggedUser, signOut } = useAuth()

    const checkLink = async () => {
        const ret = await checkValidLink(link)
        if(ret?.idmedico && loggedUser && Object.keys(loggedUser).length > 0){
            signOut()
        }
        const isLink = !isNaN(parseInt(link, 16))
        if(isLink){
            setNotFoundLinkMsg("Link não está ativo.")
        }
        setRedirectData({doctorId: ret?.idmedico, link: link})
        setRedirect(ret?.idmedico > 0)
    }

    useEffect(()=>{
        if(link == "terms"){
            setTerms(true)
        } else {
            checkLink()
        }
    }, [link, loggedUser])

    return (
        terms
        ? <Terms/>
        : redirect == true 
            ? <SubmitFiles {...redirectData}/> 
            : redirect == false 
                ? <_404 {...{notFoundLinkMsg}}/>
                : <></>
    )

}
export default NotFound