import React, { useState } from "react"
import { Alert, Box, Button, CircularProgress, Grid, Link, Paper, Snackbar, Tab, Tabs, Typography } from "@mui/material"
import OutlinedInput from '@mui/material/OutlinedInput'
import { checkCredentials, updateCredentials } from "../data/retriever"
import { useAuth } from "../context/useAuth"

const ChangePsswd = ({user, closeMainDialog}) => {
    const [snackOpen, setSnackOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorConfirmMessage, setErrorConfirmMessage] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [oldPassword, setOldPassword] = useState()
    const {loggedUser} = useAuth()

    const changePassword = async () => {
        setLoading(true)
        if(!oldPassword || !password || !confirmPassword) {
            setErrorConfirmMessage("Preencha todos os campos!")
        }
        else if(oldPassword == password) {
            setErrorConfirmMessage("Nova senha deve ser diferente da atual!")
        }
        else if(confirmPassword != password) {
            setErrorConfirmMessage("Confirmação de senha incorreta!")
        }
        else {
            const username = loggedUser?.username || user
            const objLogin = await checkCredentials({...{username, password: oldPassword}})
            if(objLogin){
                const ret = await updateCredentials({...{username, password}})
                if(ret){
                    setPassword("")
                    setOldPassword("")
                    setConfirmPassword("")
                    setErrorConfirmMessage("")
                    setSnackOpen(true)
                    if(closeMainDialog){
                        closeMainDialog()
                    }
                } else {
                    setErrorConfirmMessage("Erro: Não foi possível atualizar os dados")
                }
            } else {
                setErrorConfirmMessage("Senha atual incorreta!")
            }    
        }
        setLoading(false)
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
    }
    const handleOldPassword = (event) => {
        setOldPassword(event.target.value)
    }
    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value)
    }
    const handleSnackClose = () => {
        setSnackOpen(false)
    }

    return (
        <>
        <Snackbar
            anchorOrigin={{ vertical:"top", horizontal:"right" }}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            open={snackOpen}
        >
            <Alert
                severity={"success"}
                variant="standard"
                sx={{ width: '100%', p:2, textTransform:"uppercase"}}
            >
                {"Senha alterada com sucesso!"}
            </Alert>
        </Snackbar>

        <Grid container className="login">
            <Box className="loginItem">   
                <Typography className="title">
                    {"Mudar senha"}
                </Typography>
            </Box> 
            <Box className="loginItem"> 
                <Typography sx={{fontWeight:"bold", alignSelf:"center"}} >
                    {"Senha atual"}
                </Typography>
                <OutlinedInput 
                    name="currentPassword"
                    value={oldPassword}
                    onChange={handleOldPassword} 
                    className="avaliateButton" 
                    type="password"
                    />
            </Box>
            <Box className="loginItem"> 
                <Typography sx={{fontWeight:"bold", alignSelf:"center"}} >
                    {"Nova senha"}
                </Typography>
                <OutlinedInput 
                    name="password"
                    value={password}
                    onChange={handlePassword} 
                    className="avaliateButton" 
                    type="password"
                    />
            </Box>
            <Box className="loginItem"> 
                <Typography sx={{fontWeight:"bold", alignSelf:"center"}} >
                    {"Confirmar senha"}
                </Typography>
                <OutlinedInput 
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPassword} 
                    className="avaliateButton" 
                    type="password"
                    />
            </Box>
            <Box className="loginItem"> 
                {!loading &&
                <>
                    {errorConfirmMessage?.length > 0 &&
                        <Paper className="errorPaper" elevation={0}>{errorConfirmMessage}</Paper> 
                    }
                    <Button onClick={()=>{
                        setLoading(true)
                        changePassword()
                        }} 
                        sx={{width:"100%"}}>
                        <Typography variant="body2" textTransform="uppercase" >
                        {"Mudar senha"}           
                        </Typography>
                    </Button>                  
                </>
                }
                {loading &&
                <Box className="progress" visibility="visible">
                    <CircularProgress />
                </Box>
                }
            </Box>
        </Grid> 
        </>
    )
}

export default ChangePsswd
