import React, { useEffect } from "react"
import { Grid } from "@mui/material"

const PdfViewer = ({url, readonly}) => {
    return (    
        <Grid container className="pdfviewer" >
            <embed 
                // data={url + `${readonly ? "#toolbar=0" : ""}`}
                src={url}
                width="100%" 
                height="100%"
            />
            {readonly &&
                <>
                <div 
                    onContextMenu={(e) => {
                        e.preventDefault()
                    }} 
                className="topOverlayPdf"/>
                <div
                    onContextMenu={(e) => {
                        e.preventDefault()
                    }} 
                className="overlayPdf"/>
                </>
            }
          </Grid>
      )
}
export default PdfViewer
