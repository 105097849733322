import React, { useEffect, useState } from "react"
import { Box, Grid, Button, Typography, TablePagination, Dialog, DialogTitle, IconButton, DialogContent, Divider, DialogActions, CircularProgress, Paper } from "@mui/material"
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useNavigate } from "react-router-dom"
import {getClinicCases, getEvaluatedCases} from "../data/retriever"
import { useAuth } from "../context/useAuth"
import { CloseRounded } from "@mui/icons-material"
import Terms from "../pages/Terms"
import { updateTermsStatus } from "../data/ingester"

const ListClinicCases = ({currentIndexTab, categories, category}) => {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(true)
    const [wait, setWait] = useState(false)
    const [onlyEvaluated, setOnlyEvaluated] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [clinicCases, setClinicCases] = useState([])
    const [fullClinicCases, setFullClinicCases] = useState([])
    const [currentClinicCases, setCurrentClinicCases] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const navigate =  useNavigate()
    const { loggedUser, signIn } = useAuth()
    
    const columns = onlyEvaluated
        ?[
            {
                title: "Arquivo", 
                align: "left",
                width: "40%"
            },{
                title: "Categoria", 
                align: "left",
                width: "20%"
            },{
                title: "Nota Final", 
                align: "center",
                width: "15%"
            },
            {
                width: "25%"
            }
        ]
        :[
            {
                title: "Arquivo", 
                align: "left",
                width: "40%"
            },{
                title: "Categoria", 
                align: "left",
                width: "35%"
            },
            {
                width: "25%"
            }
        ]
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        const newIndex = newPage * rowsPerPage
        setCurrentClinicCases(clinicCases.slice(newIndex, newIndex+rowsPerPage))
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
    }

    const listClinicCases = async (category) => {
        setLoading(true)
        const allCases = await getClinicCases(loggedUser?.username)
        const categoryId = category?.categoria
        var filteredCases = categoryId
            ? allCases.filter((clinicCase) => clinicCase.categoria == categoryId)
            : allCases

        setClinicCases(filteredCases)
        setLoading(false)
    }

    const listEvaluatedClinicCases = async () => {
        setLoading(true)
        const evaluatedCases = await getEvaluatedCases(loggedUser?.username)
        const uniqueEvaluatedCases = evaluatedCases.filter((ccase, index) => 
            index == evaluatedCases.findIndex((item) => 
                item.anexo == ccase.anexo && 
                item.id_anexo == ccase.id_anexo && 
                item.categoria == ccase.categoria
            )
        )
        setClinicCases(uniqueEvaluatedCases)
        setFullClinicCases(evaluatedCases)
        setLoading(false)
    }

    useEffect(() => {
        if(category?.onlyEvaluated){
            listEvaluatedClinicCases()
            setOnlyEvaluated(true)
        } else {
            listClinicCases(category)
            setOnlyEvaluated(false)
        }
    }, [category])

    useEffect(()=>{
        handleChangePage( null, 0)
    }, [rowsPerPage, clinicCases])

    const filterGrades = ({idAttachment, attachment, category}) => {
        const currentEvaluatedCases = fullClinicCases.filter(ccase => 
            ccase.anexo == attachment &&
            ccase.id_anexo == idAttachment &&
            ccase.categoria == category
        )
        var objGrades = {}
        currentEvaluatedCases.forEach((ecase) => {
            objGrades[ecase.idPergunta] = { value: ecase.nota}
        })
        return objGrades
    }

    const calcSumGrades = ({idAttachment, attachment, category}) => {
        const currentEvaluatedCases = fullClinicCases.filter(ccase => 
            ccase.anexo == attachment &&
            ccase.id_anexo == idAttachment &&
            ccase.categoria == category
        )
        var currentNote = currentEvaluatedCases.reduce(
            (accumulator, currentValue) => accumulator + currentValue.nota,
            0,
        )
        return currentNote
    }

    const handleClick = (idAttachment, attachment, category) => {
        const objLastGrades = filterGrades({...{idAttachment, attachment, category}})
        navigate(
            `/evaluate/`, 
            {state:
                {
                    idAttachment: idAttachment,
                    attachment: attachment,
                    categoryName: (categories.find((cat) => cat["categoria"] == category))?.name,
                    lastGrades: objLastGrades,
                    indexTab: currentIndexTab
                }
            })
    }

    const handleOpenCloseDialog = () => {
        setOpen(!open)
    }
    const handleAgree = async () => {
        setWait(true)
        const ret = await updateTermsStatus(loggedUser.username)
        if(ret){
            loggedUser["termo"] = 1
            signIn(loggedUser)
            setOpen(!open)
        } else {
            setErrorMessage("O Aceite dos termos não pôde ser realizado.")
        } 
        setWait(false)
    }

    return (
        < >        
            {loading && 
                <Grid container   >

                    <Grid item xs={12} >
                    <div className="skeleton skeleton-header"></div>
                    <div className="skeleton skeleton-line"></div>
                    <div className="skeleton skeleton-line"></div>
                    <div className="skeleton skeleton-line"></div>
                    <div className="skeleton skeleton-line"></div>
                    <Typography
                        variant="h6"
                        color="gray"
                        >
                        Buscando dados...
                    </Typography>
                    </Grid>
                    
                </Grid>
            }
            {!loading && 
            <>
                {loggedUser?.termo == 0 &&
                <Dialog fullWidth maxWidth="md" onClose={handleOpenCloseDialog} open={open}>
                    <DialogTitle sx={{display:"flex", justifyContent:"right", alignItems:"center"}}>
                        <>
                        <IconButton onClick={handleOpenCloseDialog}>
                            <CloseRounded fontSize="medium" />
                        </IconButton>
                        </>
                    </DialogTitle>
                    <DialogContent>
                        <Terms/>
                        {errorMessage.length > 0 &&
                            <Paper className="errorPaper" elevation={0}>{errorMessage}</Paper> 
                        }
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        {wait &&
                        <Box className="progress" visibility="visible">
                            <CircularProgress />
                        </Box>
                        }
                        {!wait &&
                        <>
                        <Button onClick={handleAgree}>
                            Concordo
                        </Button>
                        </>
                        }
                    </DialogActions>
                </Dialog>
                }
                { (!clinicCases || clinicCases.length == 0)
                &&          
                    <Typography
                    variant="h6"
                    >
                    {"Não foram encontrados registros"}
                    </Typography>                   
                }
                {clinicCases?.length > 0 &&
                <TableContainer >
                    <Table sx={{ minWidth: 650 }} size="medium" aria-label="clinic cases">
                        <TableHead>
                        <TableRow>
                            {columns.map((column, index)=>(  
                                <TableCell key={index} width={column.width} align={column.align}>{column.title}</TableCell>                              
                            ))}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {currentClinicCases.map((clinicCase, index) => {
                            const total = calcSumGrades({...{
                                idAttachment: clinicCase.id_anexo, 
                                attachment: clinicCase.anexo, 
                                category: clinicCase.categoria
                            }})
                            return (
                            <TableRow key={index}>
                                <TableCell align="left" >
                                    <Typography className="tableCellText">
                                        {clinicCase.anexo}          
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography className="tableCellText" >
                                        { (categories.find((cat) => cat["categoria"] == clinicCase.categoria))?.name    }          
                                    </Typography>
                                </TableCell>
                                {onlyEvaluated && 
                                    <TableCell align="center">
                                        <Typography className="tableCellText" >
                                            {total}          
                                        </Typography>
                                    </TableCell>
                                }
                                <TableCell align="center">                             
                                    <Button disabled={loggedUser?.termo == 0} className="avaliateButton" onClick={()=>{
                                        handleClick(clinicCase.id_anexo, clinicCase.anexo, clinicCase.categoria)
                                        }}
                                        >
                                        <Typography variant="body2" >
                                        {"Avaliar"}           
                                        </Typography>
                                    </Button>                      
                                </TableCell>
                            </TableRow>
                            )
                        })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={clinicCases.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                    />
                </TableContainer>
                }
            </>
            }
        </>)
}
export default ListClinicCases
