import React, { useCallback, useEffect, useState } from "react"
import { Alert, Box, Button, Card, CardContent, CircularProgress, FormControl, Grid, Link, MenuItem, Select, Snackbar, Tab, Tabs, Typography } from "@mui/material"
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import CopyToClipboard from "react-copy-to-clipboard"
import { createLink } from "../data/ingester"
import debounce from "debounce"
import { checkAvailableLink, getAllDoctors } from "../data/retriever"

const GenerateLink = () => {
    const [link, setLink] = useState('')
    const [loading, setLoading] = useState(false)
    const [snackOpen, setSnackOpen] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const [doctorsList, setDoctorsList] = useState([])
    const [doctorId, setDoctorId] = useState("")

    const getDoctorsList = async () => {
        const doctors = await getAllDoctors()
        setDoctorsList(doctors)
    }

    useEffect(() => {
        getDoctorsList()
    }, [])

    const handleChange = async (event) => {
        const id = event.target.value
        setDoctorId(id)
        setDisableButton(false)
        setLink("")
        if(id > 0){
            setLoading(true)
            const lastLink = await checkAvailableLink(id)
            if(lastLink){
                setDisableButton(true)
                setLink(`${window.location.origin}/${lastLink}`)
            }
            setLoading(false)
        }
    }
    const generateLink = async () => {
        const now = new Date()
        const timestamp = now.getTime()
        const newLink = timestamp.toString(16)
        var resp = false
        if(doctorId > 0){
            setLoading(true)
            resp = await createLink(doctorId, newLink)
            if(resp) {
                const fullLink = `${window.location.origin}/${newLink}` 
                setLink(fullLink)
            }
            else {
                setSnackOpen(true)
            }
            setLoading(false)
        }
        setDisableButton(resp)
    }

    const handleDebounceLink = debounce(generateLink, 300)
    const handleCallBackLink = useCallback(()=>{
        setDisableButton(true)
        handleDebounceLink()
    }, [doctorId])

    const handleSnackClose = () => {
        setSnackOpen(false)
    }

    return (    
        <Box className="mainBox">
            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                autoHideDuration={6000}
                open={snackOpen}
                onClose={handleSnackClose}
            >
                <Alert
                    onClose={handleSnackClose}
                    severity={"error"}
                    variant="standard"
                    sx={{ width: '100%', p:2, textTransform:"uppercase"}}
                >
                    {"Erro: Não foi possível realizar a operação."}
                </Alert>
            </Snackbar>
            <Grid container sx={{ width: "800px" }} gap={4}>
                <Grid container item xs={12}>
                    <Typography className="title">
                        {"Gerar link"}
                    </Typography>
                </Grid>
                <Grid container item xs={12} gap={1} sx={{height:"56px"}}>
                    <Grid item xs={8}>
                        <FormControl sx={{width:"100%", height:"100%"}}>
                            <Select
                                value={doctorId}
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem disabled value="">
                                    <em>Médico</em>
                                </MenuItem>
                                {doctorsList?.map((doctor, index)=>{
                                    return(
                                        <MenuItem 
                                            key={index}    
                                            value={doctor.id}>
                                            {doctor.nome}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} >
                        {!loading &&
                        <Button disabled={disableButton || doctorId == ""} onClick={() => handleCallBackLink(doctorId)} sx={{width:"100%", height:"100%"}}>
                            <Typography variant="body2" >
                                {"Gerar link"}
                            </Typography>
                        </Button> 
                        }
                        {loading &&
                        <Box className="progress" visibility="visible">
                            <CircularProgress />
                        </Box>
                        } 
                    </Grid>
                </Grid>

                <Grid container item xs={12} gap={1} sx={{height:"48px"}} >
                    <Grid item xs={8}>
                        <Card sx={{height:"48px", backgroundColor:"rgba(55, 65, 81, 0.12)"}} >
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} >
                                    {link}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <CopyToClipboard text={link}>
                        <Button onClick={() => { }} sx={{width:"100%", height:"100%", gap:1}}>
                            <ContentCopyRoundedIcon fontSize="small"/>
                            <Typography variant="body2" >
                                {"Copiar link"}
                            </Typography>
                        </Button>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
export default GenerateLink
