import React from 'react'
import {Box, Container, Grid, Typography} from '@mui/material'

const _404 = ({notFoundLinkMsg}) => {

    return <Box
        component="main"
        sx={{
            flexGrow: 1,
            paddingTop:6,
            paddingBottom: 15,
        }}
        className="defaultTopPanel"
    >
        <Container  maxWidth="lg">
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3" fontSize={40} color="grey" >
                        {notFoundLinkMsg || "Por questão de segurança, por favor realizar o login novamente."}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </Box>
}
export default _404