import React, { useEffect, useState } from "react"
import { Alert, Box, Checkbox, CircularProgress, Grid, Snackbar, Table, TableBody, TableCell, TableHead, Typography } from "@mui/material"

import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { getAllEvaluators } from "../data/retriever"
import { blockEvaluator } from "../data/ingester"

const SearchEvaluators = () => {
    const [snackOpen, setSnackOpen] = useState(false)
    const [evaluators, setEvaluators] = useState([])
    const [loadingRecs, setLoadingRecs] = useState(false)
    const [loadingIndex, setLoadingIndex] = useState(-1)
    
    const getEvaluators = async () => {
        setLoadingRecs(true)
        const recs = await getAllEvaluators()
        console.log("=========BUSCANDO AVALIADORES=======")
        setEvaluators(recs)
        setLoadingRecs(false)
    }
 
    const columns = [
        {
            title: "Nome Dr(a)", 
            align: "left",
            width: "40%"
        },{
            title: "Permitir acesso", 
            align: "left",
            width: "60%"
        }
    ]

    useEffect(() => {
        getEvaluators()
    }, [])

    const block = async (event, user, index) => {
        event.target.checked
        var body = {
            "user": user,
            "value": event?.target?.checked? 1 : 0
        }
        setLoadingIndex(index)
        const resp = await blockEvaluator({...{body}})
        if(resp){
            getEvaluators()
        } else {
            setSnackOpen(true)
        }
        setLoadingIndex(-1)
    }

    const handleSnackClose = () => {
        setSnackOpen(false)
    }

    return (    
        <Box className="mainBox">
            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                autoHideDuration={3000}
                onClose={handleSnackClose}
                open={snackOpen}
            >
                <Alert
                    severity={"error"}
                    variant="standard"
                    sx={{ width: '100%', p:2, textTransform:"uppercase"}}
                >
                    {"Erro: Não foi possível atualizar avaliador."}
                </Alert>
            </Snackbar>
            <Grid container  gap={4}>
                <Grid container item xs={12}>
                    <Typography className="title">
                        {"Avaliadores"}
                    </Typography>
                </Grid>

                <Grid container item xs={12} gap={1} sx={{width:"100%"}} >
                    <TableContainer >
                        <Table size="medium" aria-label="clinic cases">
                            <TableHead>
                            <TableRow>
                                {columns.map((column, index)=>(  
                                    <TableCell key={index} width={column.width} align={column.align}>{column.title}</TableCell>                              
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loadingRecs && 
                                <>
                                {evaluators?.map((ev, index) => (
                                    <>
                                    <TableRow key={index}>
                                        <TableCell align="left" >
                                            <Typography className="tableCellText">
                                                {ev.nome}          
                                            </Typography>
                                        </TableCell>
                                        {loadingIndex != index &&
                                        <TableCell align="left">
                                            <Checkbox checked={ev.ativo} onChange={(event)=>block(event, ev.user, index)} />
                                        </TableCell>
                                        }
                                        {loadingIndex == index &&
                                        <TableCell align="left">
                                            <CircularProgress />
                                        </TableCell>
                                        }
                                    </TableRow>
                                    </>
                                ))}
                                </>}
                                {loadingRecs && 
                                <TableRow>
                                    <TableCell colSpan={columns.length} sx={{padding:0}}>
                                    <Box className="progress">
                                        <CircularProgress />
                                    </Box>
                                    </TableCell>
                                </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </Box>
    )
}
export default SearchEvaluators
