import axios from "axios"

const headers = {
    'Content-Type': 'application/json'
}
const headersFile = {
    'Content-Type': 'multipart/form-data'
}
const api = process.env.REACT_APP_API_URL_BASE

const postData = ({service, body, headers}) => {
    return new Promise((resolve, reject) => {
        axios
        .post(
          api + service,
          body,
          {headers: headers}
        )
        .then(({data}) => {
            resolve(data)
        })
        .catch((e) => {
            reject(e)
        })
    })
}

export function awareTerms ({user}) {
    const service = "updatetermo"
    const body = {
        "user": user
    }
    return postData({...{service, body, headers}})
}

export function saveLink ({doctorId, link}) {
    const service = "link"
    const body = {
        "idmedico": doctorId,
        "link": link
    }
    return postData({...{service, body, headers}})
}
export function blockLink ({doctorId, link, value}) {
    const service = "linkstatus"
    const body = {
        "idmedico": doctorId,
        "link": link,
        "value": value
    }
    return postData({...{service, body, headers}})
}

export function submitFiles ({formData}) {
    const service = "upload"
    const body = formData
    return postData({...{service, body, headers: headersFile}})
}

export function submitGrades ({body}) {
    const service = "inserirnota"
    return postData({...{service, body, headers}})
}

export function updateGrades ({body}) {
    const service = "atualizarnota"
    return postData({...{service, body, headers}})
}

export function updateEvaluator ({body}) {
    const service = "atualizaravaliador"
    return postData({...{service, body, headers}})
}

export function oknokGP ({id, valor}) {
    const service = "oknokGP"
    const body = {
        "id_anexo": id,
        "valor": valor
    }
    return postData({...{service, body, headers}})
}

export function oknokAMT ({id, valor}) {
    const service = "oknokAMT"
    const body = {
        "id_anexo": id,
        "valor": valor
    }
    return postData({...{service, body, headers}})
}
