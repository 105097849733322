import React from "react"
import { Box, Button, Tab, Tabs, Typography } from "@mui/material"

const NavTab = ({handleChange, categories, currentIndexTab, setCurrentIndexTab}) => {

    return (
        <>
        { categories.length > 0 &&
        <Box sx={{display:"flex", mb:4}}>

            <Tabs
                value={currentIndexTab}
                onChange={handleChange}
                variant="scrollable"
                aria-label="Casos clínicos"
                visibleScrollbar={false}
                scrollButtons={false}
                >
                    {categories.map((cat, index) => {
                        return (
                            <Tab 
                                key={index}
                                className="tabMenu" 
                                label={
                                    <Typography sx={{textWrap:"nowrap", overflow:"hidden", fontWeight:"bold", alignSelf:"center", textOverflow:"ellipsis"}} >
                                    {cat.name}
                                    </Typography>
                                } 
                                title={cat.name} />
                            
                        )
                    })}
                </Tabs>

            </Box> 
            }
            </>
        )
}
export default NavTab
