import React, { useEffect, useState } from "react"
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableHead, Typography } from "@mui/material"
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import PdfViewer from "../components/PdfViewer"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { getCaseAttachmentsAMT, getDoctorsWithToken, getRejectedClinicCasesAMT, getSubmittedClinicCasesAMT, getValidatedClinicCasesAMT } from "../data/retriever"
import TablePagination from '@mui/material/TablePagination'
import { CheckCircleOutlineRounded, DeleteForever, ExpandLessOutlined, ExpandMoreOutlined, ImageSearchRounded } from "@mui/icons-material"
import { approveAMT, rejectAMT } from "../data/ingester"
import { removeComma, treatLocalDateTime } from "../utils/functions"

const SearchClinicCasesAMT = () => {
    const [doctorId, setDoctorId] = useState(0)
    const [title, setTitle] = useState("")
    const [openFile, setOpenFile] = useState(false)
    const [confirmDialogStatus, setConfirmDialogStatus] = useState()
    const [snackStatus, setSnackStatus] = useState({open:false, severity:"", msg: "" })

    // const [doctorsList, setDoctorsList] = useState([])
    
    const [allSentClinicCases, setAllSentClinicCases] = useState([])
    const [sentClinicCases, setSentClinicCases] = useState([])
    const [currentSentClinicCases, setCurrentSentClinicCases] = useState([])
    const [sentPage, setSentPage] = useState(0)
    const [sentRowsPerPage, setSentRowsPerPage] = useState(5)
    
    const [allValidatedClinicCases, setAllValidatedClinicCases] = useState([])
    const [validatedClinicCases, setValidatedClinicCases] = useState([])
    const [currentValidatedClinicCases, setCurrentValidatedClinicCases] = useState([])
    const [validatedPage, setValidatedPage] = useState(0)
    const [validatedRowsPerPage, setValidatedRowsPerPage] = useState(5)

    const [allRejectedClinicCases, setAllRejectedClinicCases] = useState([])
    const [rejectedClinicCases, setRejectedClinicCases] = useState([])
    const [currentRejectedClinicCases, setCurrentRejectedClinicCases] = useState([])
    const [rejectedPage, setRejectedPage] = useState(0)
    const [rejectedRowsPerPage, setRejectedRowsPerPage] = useState(5)

    const [sentAttachments, setSentAttachments] = useState({})


    const getSubmittedCases = async () => {
        const cases = await getSubmittedClinicCasesAMT(0)
        console.log("=========BUSCANDO CASOS CLINICOS ENVIADOS=======")
        setAllSentClinicCases(cases)
    }
    const getValidatedCases = async () => {
        const cases = await getValidatedClinicCasesAMT(0)
        console.log("=========BUSCANDO CASOS CLINICOS VALIDADOS=======")
        setAllValidatedClinicCases(cases)
    }
    const getRejectedCases = async () => {
        const cases = await getRejectedClinicCasesAMT(0)
        console.log("=========BUSCANDO CASOS CLINICOS VALIDADOS=======")
        setAllRejectedClinicCases(cases)
    }
    // const getDoctorsList = async () => {
    //     const doctors = await getDoctorsWithToken()
    //     console.log("=========BUSCANDO MÉDICOS=======")
    //     setDoctorsList(doctors)
    // }
    const getAttachments = async (token) => {
        const atts = await getCaseAttachmentsAMT(token)
        console.log("=========BUSCANDO DETALHES=======")
        return atts
    }

    const handleSentChangePage = (event, newPage) => {
        setSentPage(newPage)
        const newIndex = newPage * sentRowsPerPage
        setCurrentSentClinicCases(sentClinicCases.slice(newIndex, newIndex+sentRowsPerPage))
    }
    const handleSentChangeRowsPerPage = (event) => {
        setSentRowsPerPage(parseInt(event.target.value, 10))
    }
    const handleValidatedChangePage = (event, newPage) => {
        setValidatedPage(newPage)
        const newIndex = newPage * validatedRowsPerPage
        setCurrentValidatedClinicCases(validatedClinicCases.slice(newIndex, newIndex+validatedRowsPerPage))
    }
    const handleValidatedChangeRowsPerPage = (event) => {
        setValidatedRowsPerPage(parseInt(event.target.value, 10))
    }

    const handleRejectedChangePage = (event, newPage) => {
        setRejectedPage(newPage)
        const newIndex = newPage * rejectedRowsPerPage
        setCurrentRejectedClinicCases(rejectedClinicCases.slice(newIndex, newIndex+rejectedRowsPerPage))
    }
    const handleRejectedChangeRowsPerPage = (event) => {
        setRejectedRowsPerPage(parseInt(event.target.value, 10))
    }

    const columns = [
            {
                title: "Nome Dr(a)", 
                align: "left",
                width: "50%"
            },
            {
                title: "Último link gerado", 
                align: "left",
                width: "20%"
            },
            //{
            //     title: "Bloquear submissão", 
            //     align: "center",
            //     width: "20%"
            // },
            {
                width: "30%"
            }
    ]
    const innerColumns = [
        {
            title: "Arquivo", 
            align: "left",
            width: "30%"
        },{
            title: "Categoria", 
            align: "center",
            width: "10%"
        },{
            title: "Versão", 
            align: "center",
            width: "8%"
        },{
            title: "Data de Envio", 
            align: "center",
            width: "17%"
        },
        {
            width: "35%"
        }
    ]
    const validatedColumns = [
        {
            title: "Nome Dr(a)", 
            align: "left",
            width: "25%"
        },{
            title: "Arquivo", 
            align: "left",
            width: "25%"
        },{
            title: "Data de Envio", 
            align: "center",
            width: "15%"
        },{
            title: "Categoria", 
            align: "center",
            width: "10%"
        },{
            title: "Versão", 
            align: "center",
            width: "10%"
        },
        {
            width: "15%"
        }
]

    useEffect(() => {
        getSubmittedCases()
        getValidatedCases()
        getRejectedCases()
        // getDoctorsList()
    }, [])

    useEffect(()=>{
        handleSentChangePage( null, 0)
    }, [sentRowsPerPage, sentClinicCases])

    useEffect(()=>{
        handleValidatedChangePage( null, 0)
    }, [validatedRowsPerPage, validatedClinicCases])

    useEffect(()=>{
        handleRejectedChangePage( null, 0)
    }, [rejectedRowsPerPage, rejectedClinicCases])

    useEffect(() => {
        const filterSent = allSentClinicCases?.filter((ccase)=>
            ( doctorId == 0 || ccase.idmedico == doctorId)
        )
        setSentClinicCases(filterSent?.map((x) => Object.assign({}, x, {expanded : false}) ))
    }, [doctorId, allSentClinicCases])

    useEffect(() => {
        const filterValidated = allValidatedClinicCases?.filter((ccase)=>
            ( doctorId == 0 || ccase.idmedico == doctorId)
        )
        setValidatedClinicCases(filterValidated)
    }, [doctorId, allValidatedClinicCases])

    useEffect(() => {
        const filterRejected = allRejectedClinicCases?.filter((ccase)=>
            ( doctorId == 0 || ccase.idmedico == doctorId)
        )
        setRejectedClinicCases(filterRejected)
    }, [doctorId, allRejectedClinicCases])    


    const acceptCase = async (anexo_id, token)=> {
        const ok = await approveAMT(anexo_id)
        if(ok){
            updateLocalAttachments(anexo_id, token)
            getValidatedCases()
        }
        setConfirmDialogStatus()
        openSnackBar(ok)
    }
    const rejectCase = async (anexo_id, token)=> {
        const ok = await rejectAMT(anexo_id)
        if(ok){
            updateLocalAttachments(anexo_id, token)
            getRejectedCases()
        }
        setConfirmDialogStatus()
        openSnackBar(ok)
    }

    const openSnackBar = (ok) => {
        if(ok){
            setSnackStatus({open:true, severity:"success", msg: "Operação realizada com sucesso!" })
        } else {
            setSnackStatus({open:true, severity:"error", msg: "Erro: Operação não realizada." })
        }
    }

    const approvalConfirmDialog = (anexo_id, anexo, token) => {
        setConfirmDialogStatus({open:true, id:anexo_id, isApproval:true, msg: `O anexo ${anexo} será APROVADO.`, token })
    }
    const rejectionConfirmDialog = (anexo_id, anexo, token) => {
        setConfirmDialogStatus({open:true, id:anexo_id, isApproval:false, msg: `O anexo ${anexo} será REJEITADO.`, token })
    }

    const expandSentCase = async (index) => {
        const currentExpansionStatus = currentSentClinicCases[index]["expanded"]
        currentSentClinicCases[index]["expanded"] = !currentExpansionStatus
        setCurrentSentClinicCases([...currentSentClinicCases])

        if(!currentExpansionStatus){
            const token = currentSentClinicCases[index]["token"]
            if(!Object.hasOwn(sentAttachments, token)){
                sentAttachments[token]= []
                setSentAttachments({...sentAttachments})
                const att = await getAttachments(currentSentClinicCases[index]["token"])
                sentAttachments[token]= att
                setSentAttachments({...sentAttachments})
            }       
        }
    }

    const updateLocalAttachments = (anexo_id, token) => {
        if(token){
            var attachments = sentAttachments
            var updatedTokenAttachments = attachments[token].filter(att => att.id_anexo != anexo_id)
            attachments[token] = updatedTokenAttachments
            setSentAttachments({...attachments})
            if(updatedTokenAttachments.length == 0){
                // var updatedCurrentSentClinicCases = currentSentClinicCases.filter(ccase => ccase.token != token)
                var updatedCurrentSentClinicCases = currentSentClinicCases
                var currentIndex = updatedCurrentSentClinicCases.findIndex(ccase => ccase.token == token)
                updatedCurrentSentClinicCases[currentIndex]["anexos"] = false
                setCurrentSentClinicCases([...updatedCurrentSentClinicCases])
            }
        }
    }

    // const handleChange = (event) => {
    //     setDoctorId(event.target.value)
    // }
    const handleOpenCloseFileDialog = () => {
        setOpenFile(!openFile)
    }
    const handleSnackClose = () => {
        setSnackStatus({open:false})
    }
    const ConfirmationDialog = ({id, isApproval, open, msg, token}) => {
        const [wait, setWait] = useState(false)
        return (
        <Dialog
                open={open || false}
                disableEscapeKeyDown
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirma operação?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color="text" id="alert-dialog-description">
                        {msg}
                    </DialogContentText>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    {wait &&
                    <Box className="progress" visibility="visible">
                        <CircularProgress />
                    </Box>
                    }
                    {!wait &&
                    <>
                    <Button onClick={()=>{
                        setWait(true)
                        isApproval
                            ? acceptCase(id, token)
                            : rejectCase(id, token)
                        }} >
                        OK
                    </Button>
                    <Button onClick={()=>{setConfirmDialogStatus()}} autoFocus>
                        Cancelar
                    </Button>
                    </>
                    }
                </DialogActions>
            </Dialog>
        )
    }

    return (    
        <Box className="mainBox">
            <Dialog fullWidth maxWidth="md" onClose={handleOpenCloseFileDialog} open={openFile}>
                <DialogTitle sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <>
                    {title}
                    <IconButton onClick={handleOpenCloseFileDialog}>
                        <CloseRoundedIcon fontSize="medium" />
                    </IconButton>
                    </>
                </DialogTitle>
                <PdfViewer url={`https://aveluchampions.com/pdfs/${title}`}/>
            </Dialog>

            <ConfirmationDialog {...confirmDialogStatus}/>

            <Snackbar
                    anchorOrigin={{ vertical:"top", horizontal:"right" }}
                    autoHideDuration={6000}
                    open={snackStatus?.open}
                    onClose={handleSnackClose}
                >
                    <Alert
                        onClose={handleSnackClose}
                        severity={snackStatus?.severity}
                        variant="standard"
                        sx={{ width: '100%', p:2, textTransform:"uppercase"}}
                    >
                       {snackStatus?.msg}
                    </Alert>
                </Snackbar>

            <Grid container  gap={4}>
                <Grid container item xs={12}>
                    <Typography className="titleAMT">
                        {"Casos Clínicos"}
                    </Typography>
                </Grid>
                {/* <Grid container item xs={12} gap={1} sx={{height:"56px"}}>
                    <Grid item xs={8}>
                        <FormControl sx={{width:"100%", height:"100%"}}>
                            <Select
                                value={doctorId}
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>Médico</em>
                                </MenuItem>
                                {doctorsList?.map((doctor, index)=>{
                                    return(
                                        <MenuItem 
                                            key={index}    
                                            value={doctor.id}>
                                            {doctor.nome}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid> */}

                <Grid container item xs={12} gap={1} sx={{width:"100%"}} >
                <Accordion sx={{width:"100%"}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                    >
                    Enviados
                    </AccordionSummary>
                    <AccordionDetails>
                    <TableContainer >
                        <Table size="medium" aria-label="clinic cases">
                            <TableHead>
                            <TableRow>
                                {columns.map((column, index)=>(  
                                    <TableCell key={index} width={column.width} align={column.align}>{column.title}</TableCell>                              
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {currentSentClinicCases?.map((clinicCase, index) => (
                                <>
                                <TableRow key={clinicCase.id_anexo}>
                                    <TableCell align="left" >
                                        <Typography className="tableCellText">
                                            {clinicCase.nomeMedico}          
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography className="tableCellText" >
                                            {treatLocalDateTime(clinicCase.criacao)}         
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">                             
                                        <Button className="avaliateButtonAMT" 
                                            disabled={clinicCase.anexos == "false" || !clinicCase.anexos} 
                                            onClick={()=>expandSentCase(index)} 
                                            sx={{gap:1}}
                                            >
                                            <Typography variant="body2">
                                            {"Visualizar Casos Clínicos"}           
                                            </Typography>
                                            <ExpandMoreOutlined sx={{display:clinicCase["expanded"]?"none":"flex"}}  fontSize="small"/>
                                            <ExpandLessOutlined sx={{display:clinicCase["expanded"]?"flex":"none"}}  fontSize="small"/>
                                        </Button>                      
                                    </TableCell>
                                </TableRow>
                                {clinicCase.anexos &&
                                <TableRow key={`acc_${clinicCase.id_anexo}`}>
                                    <TableCell colSpan={columns.length} sx={{padding:0}}>
                                    <Accordion sx={{width:"100%"}} elevation={0} expanded={clinicCase.expanded} >
                                        <AccordionSummary sx={{height:0, minHeight:0}} >
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <TableContainer >
                                            <Table className="accordionTable" sx={{border:0}} size="small" aria-label="clinic cases">
                                                <TableHead>
                                                <TableRow>
                                                    {innerColumns.map((column, innerIndex)=>(  
                                                        <TableCell key={innerIndex} width={column.width} align={column.align}>{column.title}</TableCell>                              
                                                    ))}
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {sentAttachments[clinicCase.token]?.length == 0 &&
                                                    <TableRow>
                                                        <TableCell colSpan={innerColumns.length} sx={{padding:0}}>
                                                            <Box className="progress">
                                                            <CircularProgress />
                                                        </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                     }
                                                    {sentAttachments[clinicCase.token]?.map((detail)=>(
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography className="tableCellText" title={detail.anexo} >
                                                                {detail.anexo}          
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography className="tableCellText" >
                                                                {detail.categoria}          
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography className="tableCellText" >
                                                                {detail.versao}           
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography className="tableCellText" >
                                                                {treatLocalDateTime(detail.data)}          
                                                            </Typography>
                                                        </TableCell>
                                                            
                                                        <TableCell  align="center" sx={{display:"grid", justifyContent:"end", gridTemplateColumns:"repeat(3, 1fr)" ,gap:1 }}>                             
                                                            <Button className="detailButton" onClick={()=>{
                                                                handleOpenCloseFileDialog()
                                                                setTitle(detail.anexo)
                                                                }}
                                                                sx={{gap:1}}
                                                            >
                                                                <ImageSearchRounded fontSize="small"/>
                                                                <Typography variant="body2" >
                                                                {"Visualizar"}           
                                                                </Typography>
                                                            </Button>
                                                            <Button className="detailButton"  
                                                                // disabled={clinicCase.data_rejeicao || clinicCase.data_aprovacao} 
                                                                onClick={()=>{approvalConfirmDialog(detail.id_anexo, detail.anexo, clinicCase.token)}}
                                                                sx={{gap:1}}
                                                            >
                                                                <CheckCircleOutlineRounded fontSize="small"/>
                                                                <Typography variant="body2" >
                                                                {"Aceitar"}           
                                                                </Typography>
                                                            </Button>
                                                            <Button className="detailButton"  
                                                                // disabled={clinicCase.data_rejeicao || clinicCase.data_aprovacao} 
                                                                onClick={()=>{rejectionConfirmDialog(detail.id_anexo, detail.anexo, clinicCase.token)}}
                                                                sx={{gap:1}}
                                                            >
                                                                <DeleteForever fontSize="small"/>
                                                                <Typography variant="body2" >
                                                                {"Rejeitar"}           
                                                                </Typography>
                                                            </Button>                       
                                                        </TableCell>
                                                    </TableRow>
                                                    ))}                                               
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        </AccordionDetails>
                                        
                                    </Accordion>
                                    </TableCell>          
                                </TableRow>    
                                }
                                </>
                            ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                        component="div"
                        count={sentClinicCases.length}
                        page={sentPage}
                        onPageChange={handleSentChangePage}
                        rowsPerPage={sentRowsPerPage}
                        onRowsPerPageChange={handleSentChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                    </TableContainer>
                    </AccordionDetails>
                    
                </Accordion> 
                </Grid>
                
                <Grid container item xs={12} gap={1} sx={{width:"100%"}} >
                <Accordion sx={{width:"100%"}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                    >
                    Validados
                    </AccordionSummary>
                    <AccordionDetails>
                    <TableContainer >
                        <Table size="medium" aria-label="clinic cases">
                            <TableHead>
                            <TableRow>
                                {validatedColumns.map((column, index)=>(  
                                    <TableCell key={index} width={column.width} align={column.align}>{column.title}</TableCell>                              
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {currentValidatedClinicCases?.map((clinicCase, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left" >
                                        <Typography className="tableCellText">
                                            {clinicCase.nomeMedico}          
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography className="tableCellText" title={clinicCase.anexo}>
                                            {clinicCase.anexo}          
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" >
                                        <Typography className="tableCellText">
                                            {treatLocalDateTime(clinicCase.data_envio)}          
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" >
                                        <Typography className="tableCellText">
                                            {clinicCase.categoria}          
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography className="tableCellText" >
                                            {clinicCase.versao}           
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">                             
                                        <Button className="avaliateButtonAMT" 
                                            onClick={()=>{
                                                handleOpenCloseFileDialog()
                                                setTitle(clinicCase.anexo)
                                            }} 
                                            sx={{gap:1}}
                                            >
                                            <ImageSearchRounded fontSize="small"/>
                                            <Typography variant="body2">
                                            {"Visualizar"}           
                                            </Typography>
                                        </Button>                      
                                    </TableCell>
                                </TableRow>      
                            ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                        component="div"
                        count={validatedClinicCases.length}
                        page={validatedPage}
                        onPageChange={handleValidatedChangePage}
                        rowsPerPage={validatedRowsPerPage}
                        onRowsPerPageChange={handleValidatedChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                    </TableContainer>
                    </AccordionDetails>
                    
                </Accordion> 
                </Grid>
            
                <Grid container item xs={12} gap={1} sx={{width:"100%"}} >
                <Accordion sx={{width:"100%"}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                    >
                    Rejeitados
                    </AccordionSummary>
                    <AccordionDetails>
                    <TableContainer >
                        <Table size="medium" aria-label="clinic cases">
                            <TableHead>
                            <TableRow>
                                {validatedColumns.map((column, index)=>(  
                                    <TableCell key={index} width={column.width} align={column.align}>{column.title}</TableCell>                              
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {currentRejectedClinicCases?.map((clinicCase, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left" >
                                        <Typography className="tableCellText">
                                            {clinicCase.nomeMedico}          
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <Typography className="tableCellText" title={clinicCase.anexo}>
                                            {clinicCase.anexo}          
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" >
                                        <Typography className="tableCellText">
                                            {treatLocalDateTime(clinicCase.data_envio)}          
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" >
                                        <Typography className="tableCellText">
                                            {clinicCase.categoria}          
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography className="tableCellText" >
                                            {clinicCase.versao}           
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">                             
                                        <Button className="avaliateButtonAMT" 
                                            onClick={()=>{
                                                handleOpenCloseFileDialog()
                                                setTitle(clinicCase.anexo)
                                            }} 
                                            sx={{gap:1}}
                                            >
                                            <ImageSearchRounded fontSize="small"/>
                                            <Typography variant="body2">
                                            {"Visualizar"}           
                                            </Typography>
                                        </Button>                      
                                    </TableCell>
                                </TableRow>      
                            ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                        component="div"
                        count={rejectedClinicCases.length}
                        page={rejectedPage}
                        onPageChange={handleRejectedChangePage}
                        rowsPerPage={rejectedRowsPerPage}
                        onRowsPerPageChange={handleRejectedChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                    </TableContainer>
                    </AccordionDetails>
                    
                </Accordion> 
                </Grid>
                
            </Grid>
        </Box>

    )
}
export default SearchClinicCasesAMT
