export const perguntas = {
    1: {
        id: 1,
        desc: "Caso Original – impacto clínico / inovação e originalidade",
        peso: 3,
        pesoDesempate: 10000
    },
    2:{
        id: 2,
        desc: "Relato Detalhado – dados clínicos (ECOG, idade, etc.), antecedentes patológicos, histórico familiar, definição do diagnóstico, data de início da doença e progressões, condutas.",
        peso: 2,
        pesoDesempate: 100,
    },
    3:{
        id: 3,
        desc: "Relevância da Imagem – imagem relevante ao caso + definição e qualidade",
        peso: 1,
        pesoDesempate: 10,
    },
    4:{
        id: 4,
        desc: "Discussão adequada da literatura relacionado ao desfecho do caso",
        peso: 3,
        pesoDesempate: 1000,
    },
    5:{
        id: 5,
        desc: "Lista de referência bibliográfica (norma vancouver)",
        peso: 1,
        pesoDesempate: 1,
    }
}

export const disclaimer = 'Este site (o "Website"), acessado, é projetado exclusivamente para médicos e outros profissionais de saúde. Por favor, note que este site pode conter informações sobre produtos farmacêuticos que somente podem ser vendidos sob receita médica. De acordo com a lei brasileira, a promoção e publicidade desses produtos é restrita aos profissionais de saúde. O site contém informações destinadas exclusivamente a profissionais de saúde, portanto, é da exclusiva responsabilidade do usuário apresentar informações verdadeiras sobre os dados fornecidos e sobre o fato de exercerem profissionalmente alguma profissão que os enquadre como profissionais de saúde. Se você não é um profissional de saúde, solicitamos que abandone imediatamente a página e evite a navegação. A Merck S.A. não é responsável pelas consequências que a informação aqui publicada pode gerar no visitante que não seja profissional da saúde ou, através deste, em terceiros. As possíveis consequências da utilização desta informação sem consultar um profissional de saúde representam sérios riscos para à vida e à saúde. A Merck S.A. reserva-se o direito de revogar a senha no caso da informação não ser confiável. '