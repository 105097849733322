import { exportmeExcel } from "excel-ent"
import { perguntas } from "./constants"

function sortGrades(a, b) {
  let notaA = a["Nota"];
  let notaB = b["Nota"]
  let desempateA = a["Nota Desempate"]
  let desempateB = b["Nota Desempate"]

  if (notaA == notaB) {
      return (desempateB < desempateA) ? -1 : (desempateB > desempateA) ? 1 : 0;
  } else {
      return (notaB < notaA) ? -1 : 1;
  }
}

export const exportExcel = ({casesDash, evalCasesDash}) => {

  const uniqueAttachs = casesDash?.filter((ccase, index) => 
      index == casesDash.findIndex((item) => 
          item.id == ccase.id
      )
  )

  const content1 = casesDash?.map((item) => {
    return {
      "Anexos": item.anexo,
      "Categoria": item.categoria,
      "Data Envio": treatLocalDateTime(item.data_envio),
      "Gerente projeto": item.aprovado_gp == 1 ? "APROVADO" : item.aprovado_gp == 0 ? "REPROVADO" : "PENDENTE",
      "Médico técnico": item.aprovado_amt == 1 ? "APROVADO" : item.aprovado_amt == 0 ? "REPROVADO" : item.aprovado_gp == 0 ? "-" : "PENDENTE"
    }
  })

  const content2 = evalCasesDash?.map((item) => {
    const descAttach = uniqueAttachs?.find((ccase, index) => 
      ccase.id == item.idAnexo
    )?.anexo 

    return {
      "Anexos": descAttach,
      "Login": item.login,
      "Data": treatLocalDateTime(item.Data),
      "Pergunta": perguntas[item.idPergunta]?.desc,
      "Nota": item.nota
    }
  })

  var originData = [
    {
      "content": content1,
      "sheetName": "Submetidos"
    },
    {
      "content": content2,
      "sheetName": "Avaliacoes"
    }
  ]

  const arrayFullRanking = evalCasesDash?.map((item) => {
    const currentCaseData = uniqueAttachs?.find((ccase, index) => 
      ccase.id == item.idAnexo
    )
    const categoryAttach = currentCaseData?.categoria 
    const nameAttach = currentCaseData?.anexo 
    const notaRanking = item.nota * perguntas[item.idPergunta]?.peso
    return {
      "Categoria": categoryAttach,
      "Anexo": nameAttach,
      "Medico": item.nomeMedico,
      "Id Pergunta": item.idPergunta,
      "Nota": notaRanking,
      "Nota Desempate": notaRanking * perguntas[item.idPergunta]?.pesoDesempate,
    }
  })

  const groupedCategoryGradesData = Object.groupBy(arrayFullRanking, ({ Categoria }) => Categoria)
  const categoryKeys = Object.keys(groupedCategoryGradesData)

  var arrayRanking = []

  for (let categoryKey of categoryKeys) {
    var grades = []
    const catArray = groupedCategoryGradesData[categoryKey]

    for (const evaluation of catArray) {
      const recordIndex = grades.findIndex((element)=>{ 
        return element["Anexo"] == evaluation["Anexo"]
      })
      if(recordIndex < 0){
        grades.push(
          {
            "Medico": evaluation["Medico"],
            "Anexo": evaluation["Anexo"],
            "Nota": evaluation["Nota"],
            "Nota Desempate": evaluation["Nota Desempate"],
          }
        )
      } else {
        grades[recordIndex]["Nota"] += evaluation["Nota"]
        grades[recordIndex]["Nota Desempate"] += evaluation["Nota Desempate"]
      }
    }

    grades.sort(sortGrades)

    originData.push(
      {
        "content": grades.map((item) => {
          return {
            "Anexo": item["Anexo"],
            "Medico": item["Medico"],
            "Nota": item["Nota"],
            "Nota Desempate": item["Nota Desempate"],
          }
        }),
        "sheetName": "Ranking Categoria " + categoryKey
      }
    )

    arrayRanking.push(
      {
        "Categoria": "Categoria " + categoryKey,
        "Postados": grades.length,
        "Nota": grades[0]["Nota"],
        "Nota Desempate": grades[0]["Nota Desempate"]
      }
    )
  }

  // arrayRanking.sort((a, b) => b["Melhor Nota"] - a["Melhor Nota"])
  arrayRanking.sort(sortGrades)
  originData.push(
    {
      "content": arrayRanking,
      "sheetName": "Ranking"
    }
  )

  var data = []
  for (let d of originData) {
    if(d["content"]?.length > 0) {
      data.push(d)
    }
  }
  
  if (data.length > 0){
    exportmeExcel({
      data,
      fileName: "Casos Clinicos",
      exportAs: {
        type: "download",
      },
      options: {
        columnWidths: [80, 30, 30, 60, 30], 
        globalRowHeight: 25, 
        headerStyle: {
          fill: {
            fgColor: {
              rgb: "4C338C", 
            },
          },
          font: {
            bold: true,
            color: {
              rgb: "ffffff", 
            },
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        },
        bodyStyle: {
          font: {
            name: "sans-serif",
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
        },
        stripedRows: true,
      },
    })
  }
  
}

export const removeComma = (word) => {
  return word.replace(',', '')
}

export const treatLocalDateTime = (stringDateTime) => {
  try {
    var localDate = new Date(stringDateTime)
    var localHours = localDate.getHours()
    localDate.setHours(localHours + 3)
    return removeComma(localDate.toLocaleString()) 
  } catch (_) {
    return ""
  }
}
